.mobile_footer {
  display: flex;
  width: 100%;
  flex-direction: column;
  color: var(--button_color);
  white-space: nowrap;
  text-align: center;
  font: 600 12px var(--font_family);
  position: fixed;
  bottom: 0;
  z-index: 99;
  left: 0;
  height: 72px;
  background-color: #fff;
}
.mobile_footer__container {
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.15);
}
.isManageBookingclick{
  position: relative;
  left: -10px;
}
.mobile_footer__home_content {
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: start;
  margin: auto 0;
}
.mobile_footer__image {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  align-self: stretch;
  margin: auto 0;
}
.mobile_footer__home_text {
  align-self: stretch;
  margin: auto 0;
  
}
.mobile_footer__home_active {
  border-radius: 20px;
  background-color: rgba(255, 247, 240, 1);
  align-self: stretch;
  display: flex;
  min-height: 32px;
  align-items: center;
  gap: 4px;
  justify-content: center;
  padding: 4px 8px;
}
.mobile_footer__home_active svg path {
  fill: var(--primary_color);
}

.mobile_footer__home_active .mobile_footer__home_text{
  color: var(--primary_color);
text-align: center;
font-family: var(--font_family);
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: normal; 

}
.managebookingnav{
  position: relative;
  left: -20px;
}
/* .mobile_footer__container div:nth-child(2) {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
} */