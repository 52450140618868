.container {
  height: 50px;
  background-color: var(--primary_color);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.marquee-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  background: transparent;
  padding-right: 20px;
  padding-left: 20px;
  align-items: center;
}

.counter {
  position: absolute;
  left: 1%;
  color: #fff;
  font-weight: 500;
  z-index: 1;
  font-family: var(--font_family);
  font-size: 14px;
}

.marquee-container {
  position: relative;
  width: 96%;
  overflow: hidden;
  padding-left: 40px;
}

.marquee-wrapper-single {
  padding-left: 0px;
}

.marquee-text {
  color: #fff;
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  width: 96%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  animation: slideUp 4s cubic-bezier(0.25,0.1,0.25,1) forwards;
  display: none;

}

.marquee-text-single {
  color: #fff;
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  display: block;
  width: 96%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.marquee-text-active{
  display: block;
}
@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 1;
  }
  25% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  65% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}


@keyframes marqueeScrollUp {
  0% { transform: translateY(0%); }
  50% { transform: translateY(-100%); }
  100% { transform: translateY(0%); }
}
.close-icon {
  cursor: pointer;
}


@media screen and (max-width: 767px) and (min-width: 320px){
  .marquee-wrapper {
    padding-right: 7px;
    padding-left: 45px;
    align-items: center;
  }

  .counter {
    left: 1.2%;
  }

  .marquee-container {
    width: 100%;
    padding: 0;
    padding-left: 0px;
  }

  .marquee-wrapper-single {
    padding-left: 20px;
  }
}
