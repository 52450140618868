.footer_main {
    background: var(--primary_color);
    background: #f0f0f0;
    color: var(--font_color);
    font-family: var(--font_family);
    font-size: 14px;
    padding: 0px 0;
    padding-bottom: 0px;
}


.travel_copyright {
    background-color: 
    #212121;
    color: #7d7d7d;
    text-align: left;
    padding: 5px 15px;
    font-size: 13px;
}
.footer_send_email {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.social_media input, .social_media img {
    height: 35px;
    width: 35px;
    object-fit: contain;
}

.social_media {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
    margin: 10px 0;
}


/* new footer design */

.footer_contMain {
    margin: 0;
    padding-bottom: 0px;
}

.footer_service {
    padding: 0;
}

.footer_serviceHead {
    font-size: 20px;
    font-weight: bold;
    color: var(--secondary_color);
    margin-right: 20px;
}

.footer_serviceList ul {
    list-style: none;
    padding: 0;
}

.footer_serviceList li {
    font-size: 15px;
  font-weight: normal;
  line-height: 2.33;
  letter-spacing: 0.38px;
  text-align: left;
  color: var(--font_color);
  cursor: pointer;
}
.footer_serviceList ul li:hover{
    color: var(--primary_color);
}
.footer_service_flx{
    display: flex;
    gap: 10px;
    align-items: flex-start;
    justify-content: space-between;
}
.footer_service_flx .footer_service{
    /* width: 25%;
    max-width: 25%; */
}
.footer_downCont{
    display: flex;
    margin-top: 10px;
}
.download_platform{
    display: flex;
    align-items: center;
    gap: 10px;
}

.foot_btmSrv{
    margin-bottom: 0px;
    display: flex;
    align-items: center;
}
.trav_copyFlx{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.trav_copyIcon{
    padding: 2px 0;
}
.footer_aboutCont{
    display: flex;
    flex-wrap: wrap;
    outline: none;
}
.footer_aboutBox{
    width: 50%;
}
.footer_aboutCont a{
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: 400;
    line-height: 2.33;
    letter-spacing: .38px;
    text-align: left;
    color: var(--font_color);
    cursor: pointer;
    /* width: 49%;
    display: block; */
    position: relative;
    padding-left: 20px;
    white-space: nowrap;

}
.footer_socialWp{
    width: 29%;
    max-width: 33%;
}
.card_icon{
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
    margin: 10px 0;
}
.card_icon img{
    height: 30px;
    /* width: 35px; */
    -o-object-fit: contain;
    object-fit: contain;
}
.footer_logo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    /* width: 20%; */
    text-align: center;
}

.footer_logo input, .footer_logo img {
    height: 100px;
    /* width: 100%; */
    width: auto;
    object-fit: scale-down;
    margin-bottom: 20px;
}
.footer_mainCont{
    width: 50%;
}
.footer_aboutCont a::before{
    content: "➢";
    display: block;
    position: absolute;
    left: 0px;
    width: 5px;
    height: 7px;
    z-index: 1;
    top: -7px;
    /* top: 50%;
    left: -2px;
    margin-top: -8px; */
}
.download_desktopHide{
    display: none;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .footer_serviceHead {
        font-size: 16px;
        font-weight: bold;
        color: var(--secondary_color);
        margin-right: 20px;
        width: 40%;
    }
    footer .container{
        width: 100%;
    }
    footer .footer_service, footer .footer_service_flx {
        display: block;
    }
    footer .footer_mainCont {
        width: 100%;
    }
    footer .footer_socialWp {
        width: 100%;
        max-width: 100%;
    }
    footer .trav_copyFlx {
        display: block;
    }
    footer .footer_logo{
        display: none;
    }
    footer .footer_service{
        margin-top: 20px;
    }
    footer .social_media img{
        height: 44px;
        width: 44px;
    }
    .travel_copyright .container{
        width: 100%;
    }
    footer .trav_copyFlx {
        display: block;
        text-align: center;
        width: 100%;
    }
    footer .footer_contMain{
        padding-bottom: 0px;
        width: 100%;
    }
    footer .social_media {
        display: flex;
        align-items: center;
        gap: 24px;
    }
    .travel_copyright {
        padding: 30px 15px;
    }
    div.container{
        width: 100%!important;
    }

    .download_container{
        padding: 0;
    }
    .download_Title{
        font-family: var(--font_family);
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fff;
        text-align: center;
    }
  
    .download_main_wrap{
        display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 20px;
    }
    .download_App_wp{
        margin: 0 5px;
        cursor: pointer;
    }
    .travel_copyright{
        padding: 15px 15px;
    }
    .download_mobileShow{
        display: block;
    }

}

