.header_section_cont{
    padding: 0;
    display: flex;
    gap: 40px;
    place-self: flex-start;
    margin-top: 10px;
}
.header_main_wd{
    width: 20%;
    place-self: flex-start;
    margin-top: 10px;
}
.header_logo {
    height: 60px;
    width: auto;
    cursor: pointer;
}

.header_sections {
    display: inline-flex;
    margin-right: 20px;
    font-size: 9px;
    padding-top: 4px;
}
.menu_text{
    font-size: 12px;
    font-weight: normal;
    color: var(--primary_color);
}
.header_sections a {
    font-size: 14px;
    color: var(--primary_color);
    cursor: pointer;
}
.active {
    border-bottom: 2px solid var(--secondary_color);
}

.header_sections input[type="image"],.header_sections img {
    width: 36px;
    height: 35px;
    object-fit: scale-down;
    filter: invert(1);
}
.logindrop {
    position: absolute;
    width: 260px;
    object-fit: contain;
    box-shadow: 0 0 5px 2px rgb(0 0 0 / 15%);
    background-color: #ffffff;
    right: 1px;
    top: 72px;
    padding: 10px;
    user-select: none;
    text-align: left;
    z-index: 99;
  }
  .logindrp{
    border-radius: 5px;
  }
  .drpHelp:before{
    content: "";
    display: block;
    position: absolute;
    top: -7px;
    left: 121px;
    border-color: #e0e0e0;
    border-left: 8px solid #fff;
    border-bottom: 8px solid #fff;
    border-right: 8px solid transparent;
    border-top: 8px solid transparent;
    transform: translateX(-50%) rotate(135deg);
    box-shadow: -2px 2px 3px rgba(57,73,76,.2);
  }
  .drpLogin::before{
    content: "";
    display: block;
    position: absolute;
    top: -7px;
    right: 2px;
    border-color: #e0e0e0;
    border-left: 8px solid #fff;
    border-bottom: 8px solid #fff;
    border-right: 8px solid transparent;
    border-top: 8px solid transparent;
    transform: translateX(-50%) rotate(135deg);
    box-shadow: -2px 2px 3px rgba(57,73,76,.2);
  }

  .logindrop .ax-head-menu {
    padding: 10px;
    cursor: pointer;
  }


.login_btn {
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    /* height: 41px;
    border: 1px solid var(--secondary_color);
    background-color: var(--secondary_color); */
    display: flex;
    align-items: center;
    padding: 3px 8px;
    white-space: nowrap;
}

.login_btn button {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    color: var(--font_color);
}

.login_btn input, .login_btn img {
    height: 17px;
    width: 17px;
    margin-right: 8px;
    object-fit: scale-down;
}

.support_btn {
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: right;
    color: var(--font_color);
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    margin-left: 25px;
}

.support_btn input, .support_btn img{
    height: 23px;
    width: 23px;
    object-fit: scale-down;
    margin-right: 8px;
}

.header_all_section{
    padding: 10px 10px 8px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-evenly;
    margin: 4px 0;
    border-radius: 10px 10px 0px 0px;
}

.header_all_section .header_sections:last-child{
    margin-right: 0;
}


.header-new{
    background: #fff;
    width: 100%;
    position: fixed;
    z-index: 999;
    box-shadow: 0px 4px 5px 0 rgb(0 0 0 / 10%);
}
div.header_section{
    width:1270px;
    margin: 0 auto;
}

.header_section{
    padding-top: 0px;
    position: fixed;
    z-index: 999;
    background: #fff;
    /* box-shadow: 0px 1px 5px 0 rgb(0 0 0 / 10%); */
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    width: 100%!important;
    /* height: 80px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    padding-bottom: 5px;
}
/* 
.header_section>div{
max-width: 1200px;
margin: 0 auto;
} */
/* 
img.header_logo {
    height: 57px;
}
.header_sections img {
    width: 22px;
    height: auto;
    margin-bottom: 6px;
}
.login_btn img {
    width: 12%;
    margin-right: 5px;
}
.support_btn img {
    width: 23%;
    margin-right: 5px;
} */

.profile_details_hide{
    display: none;
    }


    .show {
        position: absolute;
        width: 250px;
        object-fit: contain;
        /* box-shadow: 0 0 5px 2px rgb(0 0 0 / 15%); */
        border: 1px solid #e0e0e0;
        background-color: #ffffff;
        right: -10px;
        top: 35px;
        /* padding: 10px; */
        user-select: none;
        text-align: left;
        z-index: 99;
        border-radius: 5px;
    }

 /* .show:before {
  z-index: 10;
     content: "";
     position: absolute;
     width: 0;
     height: 0;
     margin-left: 0;
     bottom: 0;
     top: 0;
     right: -7px;box-sizing: border-box;
     border: 7px solid black;
     border-color: transparent transparent #fff #fff;
     transform-origin: 0 0;
     transform: rotate(130deg);
    }
    .show:after {
        z-index: -10;
     content: "";
     position: absolute;
     width: 0;
     height: 0;
     margin-left: 0;
     bottom: 0;
     top: 0;
     right: -7px;box-sizing: border-box;
     border: 7px solid #fff;
     border-color: transparent transparent #fff #fff;
     transform-origin: 0 0;
     transform: rotate(130deg);
     box-shadow: -2px 2px 4px 0px rgb(0 0 0 / 25%);
    } */

    .profile_frst {
        padding: 10px;
        }
        .profile_frst .menu_icon{
            padding-bottom: 0px;
        }
        .profile_frst > span {
            display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 400;
        margin: 12px 0;
        cursor: pointer;
        color: var(--font_color);
        padding: 3px 10px;
        }
        .profile_frst > span:hover{
            font-weight: bold;
            color: #fff;
            background: var(--primary_color);
            border-radius: 50px;
        }
        .profile_frst > span img{
        height: 20px;
        width: 20px;
        /* margin-right: 12px; */
        }
        .profile_frst>span:hover > .menu_icon {background: var(--primary_color);}

        .profile_frst>span:hover > .menu_icon img {
            filter: invert(99%) sepia(12%) saturate(0%) hue-rotate(52deg) brightness(112%) contrast(100%);
        }
        .profile_frst > a > span {
            display: flex;
        align-items: center;
        word-break: break-all;
        font-size: 13px;
        font-weight: 400;
        margin: 12px 0;
        cursor: pointer;
        color: var(--font_color);
        }
        .profile_frst > a > span:hover{
            font-weight: bold;
            color: var(--primary_color);
        }
        .profile_frst > a > span img{
        height: 20px;
        width: 20px;
        /* margin-right: 12px; */
        }
        .profile_frst>a>span:hover > .menu_icon {background: var(--primary_color);}

        .profile_frst>a>span:hover > .menu_icon img {
            filter: invert(99%) sepia(12%) saturate(0%) hue-rotate(52deg) brightness(112%) contrast(100%);
        }
        .support_scnd {
            padding-top: 0;
            border-top: 1px solid #e0e0e0;
        }
        .menu_icon{
            padding: 5px;
            /* background: #e7e7e7; */
            border-radius: 50px;
            /* margin-right: 12px; */
            padding-bottom: 0;
        }
        .menu_icon img{
            height: 25px;
            margin: auto;
            margin-bottom: 5px;
        }
        .profile_frst .support_heading {
            font-size: 18px;
            font-weight: 600;
            padding-top: 20px;
            padding-bottom: 10px;
        }
        .branch_container{
            width: 1270px !important;
            margin: 0 auto;
            /* padding: 0 40px; */
        }
        .branch_title{
            /* text-align: right; */
            display: flex;
            align-items: center;
            justify-content: right;
            gap: 15px;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .branch_titleWrap{
            padding: 0;
        }
        .branch_label{
            font-family: var(--font_family);
            font-size: 13px;
            font-weight: normal;
            letter-spacing: normal;
            text-align: left;
            color: #7a7a7a;
            margin-right: 6px;
            text-transform: capitalize;
        }
        .branch_name{
            font-family: var(--font_family);
            font-size: 13px;
            font-weight: bold;
            letter-spacing: normal;
            text-align: left;
            color: var(--primary_color);
        }
        .assisted_book button{
            font-size: 14px;
            background: var(--secondary_color);
            border-radius: 50px;
            color: #fff;
            padding: 4px 15px;
        }
        .loginMain_container{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            height: 55px;
            /* background: red; */
            justify-content: flex-end;
        }
    .afterlogin{
        display: flex;
        align-items: center;
        position: relative;
        /* width: 50%; */
        justify-content: flex-end;
        cursor: pointer;
    }
    .afterlogincheck{
        display: flex;
        align-items: center;
        position: relative;
        justify-content: flex-end;
    }
    .logindrop {
        position: absolute;
        width: 250px;
        object-fit: contain;
        box-shadow: 0 0 5px 2px rgb(0 0 0 / 15%);
        background-color: #ffffff;
        right: 1px;
        top: 42px;
        padding: 10px;
        user-select: none;
        text-align: left;
        z-index: 99;
      }
      .logindrop .ax-head-menu {
        padding: 10px;
        cursor: pointer;
      }

    /* .afterlogin > span:first-child{
        background: var(--secondary_color);
        height: 35px;
        width: 35px;
        border-radius: 50%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        margin-right: 10px;
    } */
    .afterFlx{
        display: flex;
        align-items: center;
    }
    .afterFlx img{
        margin-left: 5px;
    }
    .afterlogin > span img{
        height: 15px;
        width: 15px;
        object-fit: scale-down;
        cursor: pointer;
    }

    .show >span{
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: normal;
        margin: 12px 0;
        cursor: pointer;
        color: var(--font_color);
    }

    .show>span:hover{
        font-weight: bold;
        color: var(--primary_color);
    }

    .show >span img{
        height: 25px;
        width: 25px;
        /* object-fit: scale-down; */
        margin-right: 12px;
    }
    
    .cmpny_agnt_nme{
        /* width: 25%; */
        width: auto;
    }
    .cmpny_nme_wp{
        display: flex;
        background: #efefef;
        border-radius: 50px;
        padding: 8px 15px;
        margin: 5px 0;
    }
    .cmpny_nme_wp .cmpny_title{
        font-family: var(--font_family);
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.35px;
        color: #7a7a7a;
    }
    .cmpny_name{
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.35px;
        color: var(--primary_color);
        width: 50%;
        overflow: hidden;
        position: relative;
        display: inline-block;
        margin: 0 5px 0 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .cmpny_prce_limit{
        font-size: 14px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0.35px;
        color: var(--primary_color);
        margin-left: 4px;
    }

    .currency_cont{
        padding: 0;
    }
    .crncy_flx{
        display: flex;
        padding: 3px 10px;
        border: 1px solid var(--primary_color);
        border-radius: 5px;
    }
    .crncy_flx img {
        width: 18px;
        margin-right: 5px;
    }
    .crncy_label{
        font-size: 14px;
    }
    .hlp_label{
        font-family: var(--font_family);
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.35px;
        color: var(--font_color);
    }
    .help_cont{
        padding: 0 15px;
    }
    .help_flx{
        display: flex;
        cursor: pointer;
    }

    .down_arrow{
        margin: 0 5px;
        margin-left: 10px;
        display: inline-block;
        height: 12px;
        position: relative;
        width: 12px;
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .down_arrow::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-style: solid;
        border-width: 0 0 12px 12px;
        height: 0;
        width: 0;
        border-color: transparent;
        border-bottom-color: var(--primary_color);
    }
    .help_flx svg, .crncy_flx svg, .login_btn svg{
        margin-top: -2px;
        margin-left: 5px;
        fill: var(--secondary_color);
    }

    .user_icon{
        width: 24px;
        height: 24px;
        margin-right: 5px;
        margin-top: 0 !important;
    }
    .header_menu{
        cursor: pointer;
    }
    .afterlogin > .userIcon img{
        width: 35px;
        height: 30px;
    }
    .userText{
        color: var(--primary_color);
        font-size: 14px;
        font-weight: 600;
    }
    .menu_icon_non_link{
        cursor: default !important;
    }
    .menu_icon_non_link:hover{
        background-color: #fff !important;
        color: var(--font_color) !important;
        font-weight: normal !important;
    }
    .header_main_wd {
        width: 16%; 
    } 
    .header_section_cont{
        gap:20px
    } 
    .userText {
        color: var(--primary_color);
        font-size: 14px;
        font-weight: 600;
        max-width: 210px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .menu_icon_non_link:hover > .menu_icon {background: #fff !important;}
    @media only screen and (min-width: 1025px) and (max-width: 1279px) {
       .menu_text{
        font-size: 10px;
       }

       .header_section_cont{
        gap: 30px;
       }
      } 

    .hide{
        display: none;
    }