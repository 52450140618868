/* input#search {
    background-image: url('../../../style/img/no-img-found.png');
    background-repeat: no-repeat;
    text-indent: 20px;
} */

.vt_country_select_main_container_new {
    position: relative;
  }
  
  .vt_country_select_main_container_new input.vt_inspira_country_main.vt_country_selected {
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
    /* width: 70px!important; */
    color: #545454;
    padding: 0 10px !important;
    margin-top: 13px;
    /* height: 38px!important; */
  }
  
  .vt_country_select_main_container_new input.vt_inspira_country_main.vt_country_selected:focus {
    outline: 0;
  
  }
  
  /* dropdown */
  .vt_country_select_main_container_new .vt_country_select_list_container {
    /* overflow-y: scroll!important; */
    overflow-x: hidden;
    z-index: 999;
    position: absolute;
    background: #fff;
    /* height: 130px; */
    border: 1px solid #ccc;
    width: 300px;
    margin-top: 5px;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0 0 2px 1px #c4c5c5;
    padding: 15px 10px;
  }
 .country_name_code:hover
  {
    /* background-color: #f1f2f7; */
    background-color: #256380 !important;
    color: #fff !important;
      /* border-radius: 20px; */
  }
  /* width */
.country_code_list::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.country_code_list::-webkit-scrollbar-track {
  background: #e7e7e7; 
}
 
/* Handle */
.country_code_list::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.country_code_list::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
  
  .vt_country_select_main_container_new .vt_country_select_list_container .country_name_code .ctry_code{
    font-weight: 700;
    white-space: nowrap;
  }
  .vt_country_select_main_container_new .vt_country_select_list_container .country_name_code{
    display: flex;
    align-items: center;
  }
  
  .vt_country_select_main_container_new .vt_country_select_list_container .country_name_code .ctry_name{
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
  }
  
  .vt_country_select_list_container .country_code_list{
    overflow-y: auto;
    max-height: 200px;
  }
  .no_result_found {
    padding: 8px 10px;
}
  
  
  /* width */
  .vt_country_select_main_container_new .country_code_list::-webkit-scrollbar {
    width: 8px !important;
  }
  
  /* Track */
  .vt_country_select_main_container_new .country_code_list::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
    border-radius:5px !important;
   
  }
  
  /* Handle */
  .vt_country_select_main_container_new .country_code_list::-webkit-scrollbar-thumb {
    background: var(--primary_color) !important;
    border-radius:5px !important;
  }
  
  /* Handle on hover */
  .vt_country_select_main_container_new .country_code_list::-webkit-scrollbar-thumb:hover {
    background: var(--primary_color) !important;
  }
  
  .vt_country_select_main_container_new .vt_country_select_list_container div {
    box-sizing: border-box;
    background-color: #fff;
    cursor: pointer;
    display: block;
    padding: 8px 10px;
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: .3px;
    color: #435b73;
    /* text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap; */
  }
  
  
  .vt_country_select_main_container_new .vt_country_select_list_container .no_result_found {
    text-overflow: unset;
    overflow: unset;
    white-space: unset;
    width: 100%;
    text-align: center;
  }
  
  .vt_country_select_list_container img {
    margin-right: 5px;
  }
  .country_name_code:hover span{
    color: #fff;
  }
  .vt_country_select_list_container span {
    position: relative;
    /* top: -5px; */
    padding: 5px 10px;
   color: var(--primary_color);
    font-family: var(--font_family);

    font-size: 14px;
  
    font-weight: normal;
  
    font-stretch: normal;
  
    font-style: normal;
  
    line-height: normal;
  
    letter-spacing: 0.35px;
  
    text-align: left;
  }
  
  /* selected */
  .vt_country_select_main_container_new .vt_country_select_container input.vt_inspira_country_main {
    border-radius: 5px;
      border: 1px solid #ccc;
      font-size: 14px;
      color: #545454;
      box-sizing: border-box;
      font-weight: 700;
      width: 100% !important;
      margin: 0 !important;
      border: 0 !important;
      padding: 0 !important;
      margin-left: 8px !important;
      background-color: #ffffff;
  }
  
  .vt_country_select_main_container_new .vt_country_select_container img.vt_flag_img {
    position: relative;
    top: 0;
    /* margin-right: 10px; */
    left: 0;
  }
  .cnt_img{
    margin-right: 5px;
    flex-shrink: 0;
  }
  .vt_country_select_container input{
    background-color: #fff;
    border: none !important;
    width: 100%;
  }
  .vt_country_select_container input:focus{
    outline: none;
  }
  .vt_country_select_container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px 0 10px;
    box-sizing: border-box;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    /* background: #f6f8fb; */
    /* height: 40px;*/
  }
  .vt_country_select_list_container{
    overflow-x: hidden;
    z-index: 999;
    position: absolute;
    background: #fff;
    border: 1px solid #ccc;
    width: 300px;
    margin-top: 5px;
    border-radius: 5px;
    background-color: #fff;
    /* box-shadow: 0 0 2px 1px #c4c5c5; */
    padding: 0;
  }
  .country_code_list{
    box-sizing: border-box;
    background-color: #fff;
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: .3px;
    color: #435b73;
  }
  .country_name_code{
    display: flex;
    align-items: center;
    padding: 4px 10px;
    cursor: pointer;
  }
  .ctry_name {
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
}
  
  .vt-responsive-modal-container.sign-up-modal .signupwithalldetails .vt_country_select_main_container_new .vt_country_select_container .selected_ctry_name{
    /* margin: 0 20px 0 0; */
  }
  .vt_country_select_main_container_new .vt_country_select_container .selected_ctry_name{
    /* position: absolute;
      top: 50%;
      z-index: 9;
      left: 45px;
      transform: translate(0% , -10%); */
      font-family: var(--font_family);
      color: #545454;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: .3px;
  }
  .vt_country_select_main_container_new .vt_country_select_container input.vt_inspira_country_main:focus{
    outline: 0;
  }
  
  
  
  
  
  
  
  .add-creditcard-block.add-card-number .vt_country_select_main_container_new input.vt_inspira_country_main.vt_country_selected{
    width: 100% !important;
    height: 38px!important;
  }
  
  .add-creditcard-block.add-card-number .vt_country_select_main_container_new .vt_country_select_container input.vt_inspira_country_main {
    width: 100% !important;
    height: 38px!important;
  }
  
  
  
  .vt-responsive-modal-container.sign-up-modal .signupwithalldetails .vt_country_select_main_container_new input.vt_inspira_country_main.vt_country_selected {
    border-radius: 5px;
    border: 1px solid #e9e9ea;
    font-size: 14px;
    width: 100%!important;
    color: #545454;
    padding: 0 10px;
    margin-top: 0;
    height: 47px!important;
  }
  
  .vt-responsive-modal-container.sign-up-modal .signupwithalldetails .vt_country_select_main_container_new .vt_country_select_container input.vt_inspira_country_main {
    border-radius: 0 !important;
    border: 0 !important;
    background: 0 !important;
    font-size: 14px;
    width: 100%!important;
    color: #545454;
    padding: 0 !important;
    margin-top: 0;
    height: 47px!important;
    margin-right: 0 ;
    margin-left: 15px;
  }
  
  .vt-responsive-modal-container.sign-up-modal .signupwithalldetails .vt_country_select_main_container_new input.vt_inspira_country_main.vt_country_selected.countryname_null{
    margin-left: 0;
  }
    
  .hotel-checkout-main-container .vt-checkout-container .vt-checkout-card .vt_country_select_main_container_new .vt_country_select_container input.vt_inspira_country_main {
    
    padding: 0 !important;
    height: auto !important;
    margin: 0 !important;
    margin-left: 8px !important;
    width: 100% !important;
  }
  
  .hotel-checkout-main-container .vt-checkout-container .vt-checkout-card .vt_country_select_main_container_new .vt_country_select_container img.vt_flag_img {
    margin: 0 !important;
    margin-right: 10px !important;
  }
  
  .hotel-checkout-main-container .vt-checkout-container .vt-checkout-card .vt_country_select_main_container_new input.vt_inspira_country_main.vt_country_selected {
    /* border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
    width: 100% !important;
    color: #545454;
    padding: 0 20px 0 80px;
    margin-top: 12px !important;
    height: 43px!important; */
  }
  
  
  @media screen and (max-width: 767px) and (min-width: 320px){
    .vt-profile-main-container-mobile .vt_country_select_main_container_new .vt_country_select_container input.vt_inspira_country_main {
        /* border-radius: 3px;
        border: 1px solid #5d5b62;
        font-size: 15px;
        width: 100% !important;
        color: #545454;
        padding: 0 20px 0 40px !important;
        margin-top: 0;
        height: 50px!important;
        box-sizing: border-box; */
        height: 48px !important;
    }
  
    .hotel-checkout-main-container .vt_country_select_main_container_new .vt_country_select_container{
      width: auto !important;
    }
  
    .add-creditcard-block.add-card-number .vt_country_select_main_container_new .vt_country_select_container input.vt_inspira_country_main {
        /* width: 100%!important;
        height: 50px!important; */
    }
  
    .add-creditcard-block.add-card-number .vt_country_select_main_container_new input.vt_inspira_country_main.vt_country_selected {
        /* width: 100% !important;
        height: 50px!important; */
    }
  
    .hotel-checkout-main-container .vt-checkout-container .vt-checkout-card .vt_country_select_main_container_new .vt_country_select_container input.vt_inspira_country_main {
  /*   
        width: 68%!important; */
    }
  
    .hotel-checkout-main-container .vt-checkout-container .vt-checkout-card .vt_country_select_main_container_new input.vt_inspira_country_main.vt_country_selected {
        width: 85.5%!important;
    }
  
    .vt-responsive-modal-container.sign-up-modal .signupwithalldetails .vt_country_select_main_container_new .vt_country_select_container input.vt_inspira_country_main {
        /* width: 60.5%!important; */
  
    }
  }
  
  
  
  /* width */
  .add-country-code.add-country-phone ::-webkit-scrollbar {
    width: 2px;
  }
  
  /* Track */
  .add-country-code.add-country-phone ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .add-country-code.add-country-phone ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  .add-country-code.add-country-phone ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  .vt_country_select_main_container_new::after{
    content: '';
    height: 12px;
    width: 22px;
    background: url('/images/dropdown.png') no-repeat;
    background-size: 11px;
    display: inline-block;
    position: absolute;
    top: 45%;
    padding: 0 8px;
    right: 6px;
    /* opacity: 0.6; */
  }
  
  .vt_country_select_main_container_new.open_state_dropdown::after{
    transform: rotate(180deg);
  }
  
  .vt_country_select_main_container_new.open_state_dropdown::before{
    content: '';
    width: 100%;
    height: 3px;
    background: var(--primary_color);
    display: inline-block;
    bottom: 0px;
    position: absolute;
    z-index: 9;
    border-radius: 0 0 5px 5px;
  }
  
  .vt_country_select_main_container_new input.vt_inspira_country_main.vt_country_selected.countryname_null,
  .add-traveller-block .vt_country_select_main_container_new input.vt_inspira_country_main.vt_country_selected.countryname_null{
    padding: 0 !important;
    margin: 0 !important;
    border: 0;
  }
  
  /* .add-traveller-block .vt_country_select_main_container_new .vt_country_select_container img.vt_flag_img,.hotel-checkout-main-container .vt-checkout-container .vt-checkout-card .add-traveller-block .vt_country_select_main_container_new .vt_country_select_container .selected_ctry_name{
    top: 60%;
  } */
  
  .add-traveller-block .vt_country_select_main_container_new::after{
    /* top: 65%; */
  }
  
  
  .vt_country_select_main_container_new .vt_country_select_container{
  
    display: flex;
    align-items: center;
    padding: 0 25px 0 10px;
    box-sizing: border-box;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    /* background: #f6f8fb; */
    /* height: 48px; */
  }
  
  .vt_country_select_main_container_new{
    display:block;
  }
  
  .add-country-code.add-country-phone .vt_country_select_main_container_new .vt_country_select_container {
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #ffffff;
    height: 40px;
  }
  
  .hotel-checkout-main-container .vt_country_select_main_container_new .vt_country_select_container{
    background: #fff !important;
    border: 1px solid #ccc;
    height: 46px;
    /* width: 160px; */
  }
  
  .vt-profile-main-container-mobile .vt_country_select_main_container_new{
    border: 1px solid #5d5b62;
    border-radius: 3px;
    width: 150px;
    margin-right: 12px;
  }
  
  .vt-profile-main-container-mobile .vt_country_select_main_container_new .vt_country_select_container{
    background: #fff;
  }
  
  .vt-profile-main-container-mobile .vt_country_select_main_container_new .vt_country_select_container .selected_ctry_name,
  .hotel-checkout-main-container .vt_country_select_main_container_new .vt_country_select_container .selected_ctry_name{
    height: 16px;
  }
  
  .vt-profile-main-container-mobile .add-traveeler-profile,.vt-profile-main-container-mobile .vt-info-form-field.phone{
    display: flex;
    align-items: center;
  }
  
  .vt-profile-main-container-mobile .vt-info-form-field.phone .vt-error-msg-class-mobile{
    width: 100%;
  }
  
  .vt-checkout-booking-container .flex-row.wrap-flex{
    align-items: center;
    justify-content: space-between;
  }
  
  .hotel-checkout-main-container .vt_country_select_main_container_new
  {
    width: 170px;
    margin: 12px 0 10px;
  }
  .country_drop{
    width: 30%;
    margin-right: 8px;
  }

  .activities_country_code{
   width:100%
  }
  .activities_country_code.signup_country_class .vt_country_select_container{
    margin: 0;
    width: 100%;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    padding: 4px 6px;
    height: 40px;
  }

  .activities_country_code.country_drop .vt_country_select_container{
    margin: 0;
    width: 100%;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    padding: 4px 6px;
    height: 40px;
  }

  /* signup country code */

  .signup_country_class{
    width: 100%;
  }

  .signup_country_class .vt_country_select_container{
    width: 100%;
    height: 41px;
    margin: 30px 0 0;
    padding: 15px;
    border: solid 1px #c3c3c3;
    background-color: #fff;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: left;
    color: var(--font_color);
    border-radius: 0;
    justify-content: flex-start;
  }

  .signup_country_class .vt_country_select_list_container{
    border-radius: 0;
  }

  .signup_country_class .vt_country_select_main_container_new .vt_country_select_container input.vt_inspira_country_main,
  .signup_country_class .vt_country_select_main_container_new .vt_country_select_container .selected_ctry_name {
    color: var(--font_color);
  }



  @media screen and (max-width: 767px) and (min-width: 320px){
    .vt_country_select_main_container_new .vt_country_select_container{
      height: 41px;
    }
    .vt_country_select_container{
      height: 41px;
    }
    .country_drop{
      width: 100%;
      margin-right: 8px;
    }
  }

