._error {
  color: red;
  margin: 5px;
}

.login_loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 38px;
  height: 38px;
  position: relative;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  top: -76px;
  left: 45%;
  right: 50%;
}
.personal_login_text >div{
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.personal_login_text {
  padding: 6px 20px;
  margin: 0 0 30px 0;
  width: 100%;
  border-radius: 30px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  display: flex;
  font-family: var(--font_family);
align-items: center;
  font-size: 18px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.45px;

  text-align: left;

  color: #4a4a4a;
}

.login_by_mail_id_text {
  font-family: var(--font_family);
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.75px;
  text-align: left;
  color: var(--primary_color);
}

.login_via_otp {
  width: 101px;
  height: 16px;
  margin: 2px 67px 44px 40px;
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  text-align: left;
  color: var(--primary_color);
}

.email_id:focus {
  box-shadow: none;
  outline: none;
  border-color: #c3c3c3;
}

.email_id {
  width: 100%;
  height: 51px;
  margin: 20px 0 0;
  padding: 15px;
  border: solid 1px #c3c3c3;
  background-color: #fff;
  font-family: var(--font_family);

  font-size: 15px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.38px;

  text-align: left;

  color: var(--font_color);
}
.email_id::placeholder {
  font-family: var(--font_family);

  font-size: 15px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.38px;

  text-align: left;

  color: var(--font_color);
}
.email_id:disabled {
  background: #f0eeee;
  color: var(--font_color);
}
.forgot_password {
  margin: 20px 0 0;
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  text-align: left;
  color: var(--primary_color);
  cursor: pointer;
}

.viaotp {
  text-decoration: underline;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;

  line-height: normal;

  letter-spacing: 0.38px;

  text-align: left;

  color: var(--primary_color);
  font-family: var(--font_family);
  margin-top: 2px;
}

.new {
  font-size: 8px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.2px;

  text-align: left;

  color: #fff;
  font-family: var(--font_family);
  padding: 2px 4px 2px 5px;

  border-radius: 3.2px;

  background-color: var(--primary_color);
}

.btn_login {
  font-size: 25px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.63px;

  text-align: center;

  color: #fff;
  height: 52px;

  margin: 30px 0;

  padding: 13px;
  width: 100%;

  background-color: var(--buttons_color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.or_login_with {
  margin: 0 0 19px 0;

  font-family: var(--font_family);

  font-size: 15px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.38px;

  text-align: center;

  color: var(--font_color);
}

.noaccount {
  margin: 34px 0 23px 0;

  font-family: var(--font_family);

  font-size: 15px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.38px;

  text-align: center;

  color: var(--font_color);
}

.noaccount span,
.policytext span {
  color: var(--primary_color);
  cursor: pointer;
}

.policytext {
  margin: 23px 0 0;

  font-family: var(--font_family);

  font-size: 12px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.3px;

  text-align: center;

  color: var(--font_color);
}

.personal_login_tab_active {
  padding: 11px 33px 11px 32px;

  background-color: var(--primary_color);
  font-size: 18px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.45px;

  text-align: center;

  color: #fff;
  font-family: var(--font_family);
  width: 50%;
  border-radius: 20px;
}

.singup_header {
  font-size: 20px;

  font-weight: 600;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.5px;

  text-align: left;

  color: var(--primary_color);
  font-family: var(--font_family);
  margin: 0px 0 10px;
}

.back {
  font-size: 15px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.38px;

  text-align: left;

  color: var(--primary_color);
  font-family: var(--font_family);
  margin: 7px 0 24px;
  cursor: pointer;
}

.otp_to_email {
  margin: 8px 0 10px 0;

  font-family: var(--font_family);

  font-size: 15px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.38px;

  text-align: left;

  color: var(--font_color);
}

.otp_to_email span {
  color: var(--primary_color);
}

.resend_otp {
  font-size: 13px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.33px;

  text-align: left;

  color: var(--primary_color);
  position: absolute;
  right: 15px;
  top: 58%;
  z-index: 9;
  cursor: pointer;
}

.pswd_text {
  margin: 7px 0 17px 0;

  font-family: var(--font_family);

  font-size: 14px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: 1.79;

  letter-spacing: 0.35px;

  text-align: left;

  color: var(--font_color);
}

.pswd_success {
  font-family: var(--font_family);
  margin: 18px 0 62px;

  font-size: 18px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.45px;

  text-align: center;

  color: #32a709;
}

.email_error_msg {
  margin: 5px 0 0px;

  font-family: var(--font_family);

  font-size: 12px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.3px;

  text-align: left;

  color: red;
}

.back_to_login {
  margin: 48px 0;

  font-family: var(--font_family);

  font-size: 20px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.5px;

  text-align: center;

  color: var(--primary_color);
  cursor: pointer;
}

.link_to_email {
  margin: 5px 0 0;
  font-size: 12px;
  letter-spacing: .3px;
  text-align: left;
  color: var(--primary_color);
}

.valid_link {
  margin: 45px 0 0 0;

  font-family: var(--font_family);

  font-size: 13px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.33px;

  text-align: left;

  color: var(--font_color);
}

.valid_link span {
  color: var(--primary_color);
}

.accept_policy {
  margin: 16px 0 0 0px;
  display: flex;

  font-family: var(--font_family);

  font-size: 15px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.38px;

  text-align: left;
  position: relative;
  color: var(--font_color);
}

/* The checkbox_common */
.checkbox_common {
  display: inline-flex;
  position: relative;
  height: 20px;
  width: 20px;
  margin-right: 20px;
}

/* Hide the browser's default checkbox */
.checkbox_common input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid #888;
}

/* When the checkbox is checked, add a blue background */
.checkbox_common input:checked ~ .checkmark {
  background-color: var(--secondary_color);
  border: 1px solid var(--secondary_color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox_common input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox_common .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.cong_text {
  margin: 19px 0 45px 0;

  font-family: var(--font_family);

  font-size: 15px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.38px;

  text-align: center;

  color: var(--font_color);
}

.cong_text span {
  color: var(--primary_color);
  font-weight: bold;
}

.country_drp{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.country_drp>div:first-child{
width: 35%;
}
.country_drp>div:last-child{
  width: 62%;
}


.SignUpSuccessful{
  width: 341px;
  margin: 0 auto;
}

.SignUpSuccessful img{
  width: 56px;
  height: 56px;
  margin: 0 auto 35px;
}

.SignUpSuccessful button{
  background-color: var(--buttons_color);
  width: 303px;
  height: 51px;
  margin: 44px 19px 0;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.55px;
  text-align: center;
  color: #fff;
}

.SignUpSuccessful_inner{
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.45px;
  text-align: center;
  color: #828282;
}

.SignUpSuccessful_inner span{
  font-weight: bold;
 color: var(--primary_color);
}

.sucess_signup{
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.75px;
  text-align: center;
  color:#256380;
  margin-bottom: 24px;
}