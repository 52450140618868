.modal_container{
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9999;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal_container_top{
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9999;
  top: 130px;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal_background{
  position: fixed;
  background: rgb(0, 0, 0, .8);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}     
.modal_overflow{
  overflow: auto;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 1;
  width: 100%;
  padding:  40px 0;
}
    
.modal_content{
  position: relative;
  width: 95%;
  box-sizing: border-box;
  background: #fff;
  margin: auto;
  z-index: 2;
  max-width: 500px;
  color: var(--primary_color);
  font-family: var(--font_family);
  font-size: 12px;
  padding: 50px 70px;
  height: max-content;
  border-radius: 25px;
}
.modal_content.mobile_modal{
  padding: 20px 15px;
  border-radius: 10px;
}
.modal_close svg{
  height: 25px;
  color: var(--primary_color);
  border-radius: 50px;
  position: absolute;
  z-index: 3;
  top: 15px;
  right: 15px;
  width: 25px;
  padding: 3px;
  fill: var(--primary_color);
  border: 1px solid var(--primary_color);
  /* fill: var(--primary_color);
  border: 1px solid var(--primary_color); */
}
    
.modal_close{
  float: right;
  cursor: pointer;
  /* position: relative;
    top: -50px;
    right: -15px; */
}

.green_popup  .modal_content.mobile_modal{
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.modal_container .modal_content{
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.green_popup  .modal_content.mobile_modal{
  background-color: #1a873c;
}

.green_popup .modal_content{
  background-color: #fff;
  position: relative;
  width: 84%;
  box-sizing: border-box;
  margin: auto;
  z-index: 2;
  max-width: 515px;
  color: var(--primary_color);
  font-family: var(--font_family);
  font-size: 12px;
  padding: 60px 70px;
  height: -moz-max-content;
  height: max-content;
  border-radius: 30px;
  display: block;
}

@media screen and (max-width: 767px) and (min-width: 320px){
  .green_popup  .modal_content{
    background-color: #fff;
    position: relative;
    width: 91%;
    box-sizing: border-box;
    margin: auto;
    z-index: 2;
    max-width: 515px;
    color: var(--primary_color);
    font-family: var(--font_family);
    font-size: 12px;
    padding: 20px 15px;
    height: max-content;
    border-radius: 10px;
    display: block;
  }
  .success_email{
    padding: 20px 15px !important;
    border-radius: 10px;
  }
  .success_email .modal_close svg{
    top: 16px !important;
  }
  .modal_content{
    padding: 20px;
    /* padding-top: 45px; */
  }
  .modal_close svg{
    top: 20px;
  }
  .success_email> div{
    text-align: center;
    margin-right: 20px;
  }
  .green_popup .modal_close svg{
    top: 10px;
    right: 7px;
    color: #fff;
    border: 1px solid #fff;
  }
}

.success_email{
  position: relative;
  width: 95%;
  box-sizing: border-box;
  background: #fff;
  margin: auto;
  z-index: 2;
  max-width: 400px;
  color: var(--primary_color);
  font-family: var(--font_family);
  font-size: 15px;
  padding: 40px 100px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  border-radius: 10px;
}
@media screen and (max-width: 767px) and (min-width: 320px){

  .modal_content {
    position: relative;
    width: 95%;
    box-sizing: border-box;
    background: #fff;
    margin: auto;
    z-index: 2;
    max-width: 550px;
    color: var(--primary_color);
    font-family: var(--font_family);
    font-size: 12px;
    padding: 20px 70px;
    padding: 10px !important;
    border-radius: 10px;
}
.modal_content.mobile_modal{
  padding: 0px 0px;
  border-radius: 0px;
}
.modal_overflow{
  padding:  0px 0;
}
}


.pop_title{
  font-size: 18px;
  color: var(--primary_color);
}
