
.skContainerlarge {
    width: 1242px;
}
.skContainersmall{
    width: 1200px;
}
.searchwidgethome {
    width: 1242px;
    margin: 0 auto;
    position: relative;
}
.SkeletonMain_Page span{
    border-radius: 5px;

}
.searchwidgethome{
    margin-bottom: 30px;
}
.searchwidgethometop{
    position: absolute;
    top: -90px;
    background-color: #fff;
    width: 1025px;
    margin: 0 auto;
    transform: translate(10%, 50%);
    display: flex;
    gap: 10px;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, .3);
    background-color: #fff;
    padding: 21px;
}
.searchwidgethometop {
    display: flex;
    gap: 10px;
}
.searchwidgethomebottom{
    background-color: #fff;
    width: 95%;
    gap: 30px;
    border: 1px solid #e5e5e5;
    display: flex;
    margin: 60px;
    position: relative;
    top: 70px;
    left: -30px;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px !important;
}
.searchwidgethomewidget{
    width: 20%!important;
}
.skeletonflex{
    display: flex;
    gap: 20px;
}
.fourlayout {
    width: 1242px;
    margin: 0 auto;
    margin-bottom: 30px;
}
.fivelayout{
    width: 1242px;
    margin: 0 auto;
    margin-bottom: 30px;
}
.sixlayout{
    width: 1242px;
    margin: 0 auto;
    margin-bottom: 30px;
}
.threelayout{
    width: 1242px;
    margin: 0 auto;
    margin-bottom: 30px;
}

@media only screen and (max-width: 767px) and (min-width: 320px){

    .fourlayout, .fivelayout, .sixlayout, .threelayout , .searchwidgethomebottom, .searchwidgethometop, .searchwidgethome{
        width: 100%;
        margin-bottom: 30px;
        
    }

    .searchwidgethome{
        padding: 30px 10px 20px;
        background-color: transparent!important;
        box-shadow:unset!important;
        margin-bottom: 0px!important;
      height: unset!important;
    }
    .fourlayout, .fivelayout, .sixlayout, .threelayout {
        padding: 20px;
        padding-right: 0px;
    }
    .SkeletonMain_Page{
        display: none;
    }
    .searchwidgethometop{
        display: block;
        transform: unset;
        padding:20px;
        background-color: transparent!important;
        box-shadow:unset!important;
        top: -40px;
        border: 1px solid #e5e5e5;
        padding: 30px 20px 20px;
        /* width: 90%; */
        border-radius: 15px;
        position: unset;
      
    }
    .skeletonflextabmain{
        display: flex;
        gap: 25px;
         
        width: 1000px;
        overflow-y: scroll;
    }
    .skeletonflextab{
        text-align: center;
        display: flex;
        justify-content: center;
        margin-bottom: 15px!important;
       
    }
    .skeletonflextab span{
       border-radius: 50%!important;
       width: 45px!important;
        height: 45px!important;

    }
    .skeletonflextabwidth{
            overflow: hidden;
            padding: 0px 10px;
    }
    .SkeletonMain_Pagemobile span{
        border-radius: 5px;
    
    }

    
   
  


}
.searchwidgethome{
    /* border: 1px solid #e5e5e5; */
    height: 208px;
    border-radius: 10px;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, .3);
    background-color: #fff;
}

/* border: 1px solid #e5e5e5; */


 .SkeletonMain_Page  span, .SkeletonMain_Pagemobile span {
    --base-color: #e6e4e4;
    --highlight-color: #ededed;
    --animation-duration: 1.5s;
    --animation-direction: normal;
    --pseudo-element-display: block;
    border-radius: 0.25rem;
    display: block;
    line-height: 1;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background-color: #ebebeb;
  }
  
  .SkeletonMain_Page span::after , .SkeletonMain_Pagemobile span::after{
    content: ' ';
    display: var(--pseudo-element-display);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg,
        var(--base-color),
        var(--highlight-color),
        var(--base-color));
    transform: translateX(-100%);
  
    animation-name: react-loading-skeleton;
    animation-direction: var(--animation-direction);
    animation-duration: var(--animation-duration);
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
  
  @keyframes react-loading-skeleton {
    100% {
      transform: translateX(100%);
    }
  }
   