.download_btn{
    font-size: 14px;
    background-color: #c6e1fb;
    color: #134b89;
    text-align: center;
    font-weight: bold;
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: inherit !important;
}
.imgStyle{
    margin-right: 3px;
    width: 25px;
}

@media only screen and (max-width: 767px) and (min-width: 320px){
    .download_btn{
        display: block;
    }
    .imgStyle{
       display: inline;
    }
}