._error {
  color: red;
  margin: 5px;
}

.login_loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 38px;
  height: 38px;
  position: relative;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  top: -62px;
  left: 45%;
  right: 50%;
}
.personal_login_text > div {
  width: 100%;
  text-align: center;
  cursor: pointer;
  padding: 20px 0px 0;
}
.personal_login_text {
  padding: 40px;
  width: 460px;
}

.login_by_mail_id_text {
  display: flex;
  font-family: var(--font_family);
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.login_via_otp {
  width: 101px;
  height: 16px;
  margin: 2px 67px 44px 40px;
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  text-align: left;
  color: var(--primary_color);
}

.email_id:focus {
  box-shadow: none;
  outline: none;
  border-color: #c3c3c3;
}

.email_id {
  width: 100%;
  height: auto;
  margin: 20px 0 0;
  margin-top: 5px;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #dedddd;
  padding-left: 0px;
  background-color: #fff;
  font-family: var(--font_family);

  font-size: 15px;

  font-weight: bold;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.38px;

  text-align: left;

  color: var(--font_color);
}
.email_id::placeholder {
  font-family: var(--font_family);

  font-size: 15px;

  font-weight: bold;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.38px;

  text-align: left;

  color: var(--font_color);
}
.email_id:disabled {
  background: #f0eeee;
  color: var(--font_color);
}
.forgot_password {
  margin: 20px 0 0;
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  text-align: left;
  color: var(--primary_color);
  cursor: pointer;
}

.viaotp {
  text-decoration: underline;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;

  line-height: normal;

  letter-spacing: 0.38px;

  text-align: left;

  color: var(--primary_color);
  font-family: var(--font_family);
  margin-top: 2px;
}

.new {
  font-size: 8px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.2px;

  text-align: left;

  color: #fff;
  font-family: var(--font_family);
  padding: 2px 4px 2px 5px;

  border-radius: 3.2px;

  background-color: var(--primary_color);
}

.btn_login {
  font-size: 17px;
  font-family: var(--font_family);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  color: #fff;
  margin: 30px 0 20px;
  padding: 13px;
  width: 100%;
  background-color: var(--buttons_color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 46px;
}

.or_login_with {
  margin: 0 0 19px 0;

  font-family: var(--font_family);

  font-size: 15px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.38px;

  text-align: center;

  color: var(--font_color);
}

.noaccount {
  margin: 15px 0 23px 0;

  font-family: var(--font_family);

  font-size: 15px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.38px;

  text-align: center;

  color: var(--font_color);
}

.noaccount span,
.policytext span {
  color: var(--primary_color);
  cursor: pointer;
  font-weight: 600;
  font-family: var(--font_family);
  font-size: 14px;
}

.policytext {
  margin: 0px 0 0;

  font-family: var(--font_family);

  font-size: 13px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: 20px;

  letter-spacing: 0.3px;

  text-align: left;

  color: var(--font_color);
  padding-bottom: 15px;
}

.personal_login_tab_active {
  padding: 11px 33px 11px 32px;

  background-color: var(--primary_color);
  font-size: 18px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.45px;

  text-align: center;

  color: #fff;
  font-family: var(--font_family);
  width: 50%;
  border-radius: 20px;
}

.singup_header {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: left;
  color: var(--primary_color);
  font-family: var(--font_family);
  padding: 40px;
  width: 600px;
  /* height: 700px;
  min-height: 700px; */
  overflow-y: auto;
}

.singup_header_page {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: left;
  color: var(--primary_color);
  font-family: var(--font_family);
  padding: 40px;
  width: 600px;
}
.back {
  font-size: 15px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.38px;

  text-align: left;

  color: var(--primary_color);
  font-family: var(--font_family);
  margin: 7px 0 24px;
  cursor: pointer;
}

.otp_to_email {
  margin: 8px 0 10px 0;

  font-family: var(--font_family);

  font-size: 15px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.38px;

  text-align: left;

  color: var(--font_color);
}

.otp_to_email span {
  color: var(--primary_color);
}

.resend_otp {
  font-size: 13px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.33px;

  text-align: left;

  color: var(--primary_color);
  position: absolute;
  right: 15px;
  top: 58%;
  z-index: 9;
  cursor: pointer;
}

.pswd_text {
  margin: 7px 0 17px 0;

  font-family: var(--font_family);

  font-size: 14px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: 1.79;

  letter-spacing: 0.35px;

  text-align: left;

  color: var(--font_color);
}

.pswd_success {
  font-family: var(--font_family);
  margin: 18px 0 62px;

  font-size: 18px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.45px;

  text-align: center;

  color: #32a709;
}

.email_error_msg {
  margin: 0;

  font-family: var(--font_family);

  font-size: 12px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.3px;

  text-align: left;

  color: red;
  /* position: absolute; */
  margin-top: 4px;
}

.back_to_login {
  margin: 48px 0;

  font-family: var(--font_family);

  font-size: 16px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.5px;

  text-align: center;

  color: var(--primary_color);
  cursor: pointer;
}

.link_to_email {
  margin: 5px 0 0;
  font-size: 12px;
  letter-spacing: 0.3px;
  text-align: left;
  color: var(--primary_color);
}

.valid_link {
  margin: 45px 0 0 0;

  font-family: var(--font_family);

  font-size: 13px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.33px;

  text-align: left;

  color: var(--font_color);
}

.valid_link span {
  color: var(--primary_color);
}

.accept_policy {
  margin: 16px 0 0 0px;
  display: flex;

  font-family: var(--font_family);

  font-size: 15px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.38px;

  text-align: left;
  position: relative;
  color: var(--font_color);
}

/* The checkbox_common */
.checkbox_common {
  display: inline-flex;
  position: relative;
  height: 20px;
  width: 20px;
  margin-right: 20px;
}

/* Hide the browser's default checkbox */
.checkbox_common input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid #888;
}

/* When the checkbox is checked, add a blue background */
.checkbox_common input:checked ~ .checkmark {
  background-color: var(--secondary_color);
  border: 1px solid var(--secondary_color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox_common input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox_common .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.cong_text {
  margin: 19px 0 45px 0;

  font-family: var(--font_family);

  font-size: 15px;

  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.38px;

  text-align: center;

  color: var(--font_color);
}

.cong_text span {
  color: var(--primary_color);
  font-weight: bold;
}

.country_drp {
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
}

.country_drp > div:first-child {
  width: 35%;
}
.country_drp > div:last-child {
  width: 62%;
}

.SignUpSuccessful {
  width: 341px;
  margin: 0 auto;
  padding: 60px 0px 30px;
}

.SignUpSuccessful img {
  width: 56px;
  height: 56px;
  margin: 0 auto 15px;
}

.SignUpSuccessful button {
  background-color: var(--buttons_color);
  width: 303px;
  height: 51px;
  margin: 20px 19px 0;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.55px;
  text-align: center;
  color: #fff;
  border-radius: 5px;
}

.SignUpSuccessful_inner {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.45px;
  text-align: center;
  color: #828282;
}

.SignUpSuccessful_inner span {
  font-weight: bold;
  color: var(--primary_color);
}

.sucess_signup {
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.75px;
  text-align: center;
  color: #256380;
  margin-bottom: 24px;
}
.login_flex {
  display: flex;
  align-items: center;
}
.form_flex {
  /* padding: 50px 50px 30px; */
  width: 440px;
  padding: 0px 50px;
  padding-top: 20px;
  padding-bottom: 0;
  overflow-y: auto;
    height: 600px;
}
.login_banner img {
  height: 100%;
  width: 100%;
  max-width: unset;
}
.passowrd_instr {
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--primary_color);
  padding-top: 15px;
  padding-bottom: 30px;
}
.password_section input {
  border: none;
  border-bottom: 1px solid #dedddd;
  padding-left: 0px;
}
.login_by_mail_id_text .back_btn {
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--primary_color);
  padding-left: 80px;
}
.googleLoginBtn {
  display: block;
  margin: auto;
}
.approved_sec_mob {
  display: none;
}
.approved_sec {
  border-radius: 5px;
  background-color: #e3ffe4;
  padding: 20px;
  display: block;
}
.approved_sec img {
  width: 28px;
  height: 28px;
  margin-right: 5px;
}
.approved_sec h1 {
  display: flex;
  align-items: center;
  font-family: var(--font_family);
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.approved_sec p {
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
  padding-left: 33px;
  padding-top: 3px;
}
.input_flex {
  display: flex;
  position: relative;
  width: 90%;
}
.form_heading_text {
  padding: 12px 0;
}
.input_newcont{
  width: 100%;
}
.input_newcont .zenith_input, .input_newcont .zenith_last_name {
  width: 50%;
}
.input_newcont .error_class{
  width: auto;
}
.mob_new label, .new_cont label{
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
}
.new_cont label{
  margin-bottom: 5px;
  display: block;
}
.mob_new{
  margin-top: 20px;
}
.input_newcont{
  margin-bottom: 0;
}
.input_newcont,
.new_cont, .zenith_password,
.zenith_c_password  {
  margin-top: 20px;
}
.input_newcont input, .mob_new input,
.new_cont input, .zenith_password input,
.zenith_c_password input  {
  margin-top: 0px;
}
.input_flex input {
  margin-right: 40px;
}
.input_flex input:last-child {
  margin-right: 0px;
}
.dropdown_section input {
  align-items: center;
  font-weight: 600;
}
.dropdown_section .vt_country_select_container {
  padding-left: 0px !important;
}
.country_drp.dropdown_section > div:first-child {
  width: 55%;
}
.country_drp.dropdown_section .signup_country_class {
  width: 80%;
}
.country_drp.dropdown_section .signup_nationality_class {
  width: 87%;
  display: block;
}
.country_drp.dropdown_section > div:nth-child(2) {
  width: 33%;
  margin-left: 30px;
}
.country_drp.dropdown_section > div:last-child {
  width: 68%;
  margin-left: 40px;
}
.singup_header .email_id:disabled {
  padding-left: 20px;
}
.welcome_zenith {
  border-radius: 5px;
  background-color: #ffefe6;
  padding: 11px 32px 11px 12px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
    align-items: center;
    gap: 9px;
}
.welcome_zenith p {
  display: flex;
  align-items: center;
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
}
.welcome_zenith img {
  width: 35px;
  height: 35px;
  margin-right: 20px;
}
.dropdown_section > div > div > span > span:nth-child(1) {
  padding-left: 0px !important;
  border-radius: 0px !important;
  margin: 26px 0px 0px;
}
.dropdown_section > div > div > span > span:nth-child(3) {
  margin: 26px 0px 0px;
}
.do_later {
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--primary_color);
  cursor: pointer;
  padding-bottom: 20px;
}
.error_class:nth-child(1) {
  margin-left: 0px;
}
.error_class {
  width: 100%;
}
.marginlr {
  margin-left: 40px;
}
.error_class .email_error_msg {
  position: absolute;
}
.flex_col {
  display: flex;
  gap: 10px;
}
.google_div button {
  text-align: center;
  /* padding-left: 100px; */
}
.text_password h1 {
  font-family: var(--font_family);
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.text_password p {
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--primary_color);
}
.newPassword {
  margin-bottom: 30px;
  position: relative;
}
.newPassword:nth-child(1) {
  margin-bottom: 50px;
}
.newPassword .email_id {
  margin-top: 0px;
}
.personal_login_text button {
  width: 100%;
  border-radius: 10px;
  background-color: var(--buttons_color);
  font-family: var(--font_family);
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.newPassword label {
  text-align: left;
  display: block;
}
.flex_password {
  display: flex;
  position: relative;
}
.flex_password .checkbox_label {
  position: absolute;
  right: 0px;
  display: flex;
  top: 21px;
  font-family: var(--font_family);
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
}
.flex_password .checkbox_label input {
  border: 1px solid #dedddd !important;
  margin-right: 10px;
  cursor: pointer;
}
.flex_password {
  position: relative;
}
.special_character {
  position: absolute;
  z-index: 9;
  top: 60px;
}
.special_character p::before {
  position: absolute;
  content: "";
  border-bottom: 10px solid #0ead0b;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  left: 35px;
  top: -10px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.19);
}
.special_character p {
  text-align: left;
  border-radius: 5px;
  background-color: #0ead0b;
  padding: 10px;
  font-family: var(--font_family);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: left;
  color: #fff;
}
.flex_password .special_character {
  display: none;
}
.newPassword:hover .special_character {
  display: block;
}
.update_password {
  padding: 60px 40px 40px;
  text-align: center;
  width: 500px;
}
.update_password .login_by_mail_id_text {
  text-align: center;
  display: unset;
}
.update_password .pswd_success {
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #0ead0b;
  margin-bottom: 30px;
  padding: 0px 20px;
}
.update_password img {
  width: 60px;
  margin: auto;
  margin-bottom: 10px;
}
.dropdown_section .email_id {
  margin: 0px 0px 0px;
}
.dropdown_section .error_class > div > span > div {
  width: 192px !important;
}
.zenith_signin {
  padding-top: 20px;
}

.zenith_input {
  margin-bottom: 20px;
  position: relative;
}
.zenith_input label {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
}

.modal_container {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9999;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal_background {
  position: fixed;
  background: rgb(0, 0, 0, 0.8);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.modal_overflow {
  overflow: auto;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 1;
  width: 100%;
  padding: 40px 0;
}

.modal_content {
  position: relative;
  width: 95%;
  box-sizing: border-box;
  background: #fff;
  margin: auto;
  z-index: 2;
  max-width: fit-content;
  color: var(--primary_color);
  font-family: var(--font_family);
  font-size: 12px;
  padding: 0;
  height: max-content;
  border-radius: 5px;
}
.modal_content.mobile_modal {
  padding: 20px 15px;
  border-radius: 10px;
}
.modal_close svg {
  height: 25px;
  color: #919191;
  border-radius: 50px;
  position: absolute;
  z-index: 3;
  top: 10px !important;
  right: 10px;
  width: 25px;
  border: 1px solid #919191;
  padding: 3px;
}

.modal_close {
  float: right;
  cursor: pointer;
  /* position: relative;
    top: -50px;
    right: -15px; */
}

.green_popup .modal_content.mobile_modal {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.modal_container .modal_content {
  padding-bottom: 50px !important;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.green_popup .modal_content.mobile_modal {
  background-color: #1a873c;
}

.green_popup .modal_content {
  background-color: #fff;
  position: relative;
  width: 84%;
  box-sizing: border-box;
  margin: auto;
  z-index: 2;
  max-width: 515px;
  color: var(--primary_color);
  font-family: var(--font_family);
  font-size: 12px;
  padding: 60px 70px;
  height: -moz-max-content;
  height: max-content;
  border-radius: 30px;
  display: block;
}

@media screen and (max-width: 767px) and (min-width: 320px) {
  .zenith_input label {
    display: block;
  }
  .green_popup .modal_content {
    background-color: green;
    position: relative;
    width: 91%;
    box-sizing: border-box;
    margin: auto;
    z-index: 2;
    max-width: 515px;
    color: var(--primary_color);
    font-family: var(--font_family);
    font-size: 12px;
    padding: 20px 15px;
    height: max-content;
    border-radius: 10px;
    display: block;
  }
  .success_email {
    padding: 20px 15px !important;
    border-radius: 10px;
  }
  .success_email .modal_close svg {
    top: 16px !important;
  }
  .modal_content {
    padding: 20px;
    /* padding-top: 45px; */
  }
  .modal_close svg {
    top: 20px;
    border: none;
  }
  .success_email > div {
    text-align: center;
    margin-right: 20px;
  }
}

.success_email {
  position: relative;
  width: 95%;
  box-sizing: border-box;
  background: #fff;
  margin: auto;
  z-index: 2;
  max-width: 400px;
  color: var(--primary_color);
  font-family: var(--font_family);
  font-size: 15px;
  padding: 40px 100px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  border-radius: 10px;
}
@media screen and (max-width: 767px) and (min-width: 320px) {
  .modal_content {
    position: relative;
    width: 95%;
    box-sizing: border-box;
    background: #fff;
    margin: auto;
    z-index: 2;
    max-width: 550px;
    color: var(--primary_color);
    font-family: var(--font_family);
    font-size: 12px;
    padding: 20px 70px;
    padding: 10px !important;
    border-radius: 10px;
  }
  .modal_content.mobile_modal {
    padding: 0px 0px;
    border-radius: 0px;
  }
  .modal_overflow {
    padding: 0px 0;
  }
}

.pop_title {
  font-size: 18px;
  color: var(--primary_color);
}
.zenith_input_isd span {
  margin-top: 0px !important;
  position: relative;
  padding-left: 0px !important;
}
.zenith_last_name label {
  padding-left: 40px;
}
.signup_email {
  padding-top: 25px;
}
.modalb_wrap {
  margin-top: 15px;
  padding: 20px 70px;
}
.modalb_wrap.change_password_main{
  padding: 60px 40px 0px;
  text-align: center;
  width: 500px;
}  
.change_password_main .modb_icon{
  width: 60px;
  margin: auto auto 10px;
}
.change_password_main img{
    width: 60px;
    margin: auto auto 10px;
}
.change_password_main .login_by_mail_id_text{
  justify-content: center;
}
.change_password_main .pswd_success{
  font-family: var(--font_family);
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #0ead0b;
    margin-bottom: 30px;
    padding: 0 20px;
}
.modb_icon {
  /* margin-bottom: 10px; */
  margin: 30px auto;
  display: block;
  width: max-content;
}
.modb_icon svg{
  width: 84px;
  height: 65px;
}
.modb_icon img {
  width: 92px;
  margin: auto;
}
.modb_title {
  font-family: var(--font_family);
  /* font-family: ProximaNova; */
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.5px;
  text-align: center;
  /* color: #4b4b4b; */
  color: var(--font_color);
}
.zenith_password {
  margin-bottom: 30px;
}
.flex_div_login {
  display: flex;
  align-items: center;
  padding: 20px 0;
}
.newspan span {
  height: 50px;
  width: 62px;
  border: 1px solid var(--buttons_color);
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 0px 10px;
  background-color: var(--buttons_color);
  color: #fff;
  margin-right: 0;
  cursor: pointer;
  transition: 0.5s;
  margin-bottom: 10px;
}
.flex_col {
  flex-direction: row;
  display: flex;
  gap: 10px;
  margin-left: 15px;
  align-items: center;
}

.googleComp {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 15px;
  margin-bottom: 10px;
}

.googleLoginBtn {
  height: 50px;
  width: -moz-max-content;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  text-align: left;
  color: #4a4a4a;
  border: 1px solid var(--primary_color);
}

.Social_login {
  display: flex;
  /* justify-content: space-between; */ /*when both google and fb*/
  justify-content: center; /*when google in center*/
}
.Social_login button {
  background-color: transparent;
  border-color: transparent;
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
  height: auto;
}
.Social_login button img {
  width: auto;
  height: auto;
}
.newercontent {
  display: none;
}
.deskshow {
  display: block;
}
.heading_text_div {
  display: none;
}
.newflex {
  display: block;
}
.desktopdiv {
  display: block;
}
.deskicon{
  display: block;
}
.mobicon{
  display: none;
}

.signup_main_container :global .modal-container{
  /* example how to target common component css class */
}
.signup_main_container :global .modal-overflow{
  height: auto;
}

.signup_main_container :global .modal-content{
  height: 600px;
  overflow-y: unset;
}

.form_flex::-webkit-scrollbar, .singup_header::-webkit-scrollbar {
  width: 10px; 
}

.form_flex::-webkit-scrollbar-track, .singup_header::-webkit-scrollbar-track {
  background: #ddd;
}

.form_flex::-webkit-scrollbar-thumb, .singup_header::-webkit-scrollbar-thumb {
  background-color: var(--primary_color); 
  border-radius: 10px; 
  border: 2px solid var(--primary_color); 
}

/* .form_flex::-webkit-scrollbar-thumb:hover, .singup_header_page::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary_color); 
} */


.login_banner {
  height: 600px;
  width: 440px;
}
.country_mob_new{
  align-items: flex-end;
}


/* .zenith_input_isd>div>span>span{
  padding-left: 0px !important;
}
.zenith_input_isd>div>span>span+div{
  width: 200px !important;
  width: 178px !important;
    font-size: 13px;
    font-family: var(--font_family);
    color: var(--font_color) !important;
} */
@media screen and (max-width: 1600px) and (min-width: 1280px) {
  .form_flex {
    /* padding: 70px 50px 30px; */
    padding: 0px 50px;
    padding-top: 20px;
  }
  .singup_header {
    padding: 30px 40px 40px;
  }
  .singup_header_page {
    padding: 10px 40px 40px;
  }
}
@media screen and (max-width: 1279px) and (min-width: 1200px) {
  .singup_header {
    /* height: 810px;
    min-height: 714px; */
  }
}
.zenith_input_isd .vt_country_select_list_container {
  width: 178px !important;
  font-size: 13px;
  font-family: var(--font_family);
  color: var(--font_color) !important;
}

.mob_new{
  margin-top: 5px;
}
.zenith_password .email_error_msg {
  width: 537px;
}
.country_mob_new :global .IsdCodeDropdown_isd_search__NynyB {
  width: 100%;
  height: auto;
  margin: 5px 0 0;
  border: none !important;
  border-bottom: 1px solid #dedddd !important;
  border-radius: 0;
  padding: 10px 10px 10px 0;
  background-color: #fff;
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: .38px;
  text-align: left;
  color: var(--font_color);
}
.country_mob_new :global .IsdCodeDropdown_isd_input_wrapper__bH500 .IsdCodeDropdown_isd_search__NynyB {
  padding-left: 0px !important;
}
.country_mob_new :global .IsdCodeDropdown_isd_search__NynyB::placeholder{
  font-weight: bold;
}

.country_mob_new :global .IsdCodeDropdown_isd_dropdown__HdqG5.isd_drop_cont {
  width: 100%;
}

.country_mob_new :global .IsdCodeDropdown_isd_search__NynyB:focus {
  outline: 0;
  box-shadow: none;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .personal_login_text {
    padding: 6px 10px;
    /* margin: 30px 0; */
    width: 100%;
    /* border-radius: 30px; */
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    /* display: flex; */
    font-family: var(--font_family);
    /* align-items: center; */
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.45px;
    /* text-align: left; */
    color: #4a4a4a;
    position: absolute;
    bottom: 0;
  }
  .login_loader {
    top: -75px;
  }
  .country_mob_new :global .IsdCodeDropdown_isd_search__NynyB {
    width: 100%;
    height: 55px;
    border-radius: 5px;
    border: 1px solid #c3c3c3 !important;
    background-color: #fff;
  }
  .country_mob_new :global .IsdCodeDropdown_isd_input_wrapper__bH500 .IsdCodeDropdown_isd_search__NynyB {
    padding-left: 0px !important;
  }
  .country_mob_new :global .IsdCodeDropdown_isd_search__NynyB::placeholder{
    font-weight: 500;
  }
  
  .country_mob_new :global .IsdCodeDropdown_isd_dropdown__HdqG5.isd_drop_cont {
    width: 100%;
  }
  .country_mob_new :global .isd_input_cont {
    height: 50px;
  }
  
  .country_mob_new :global .IsdCodeDropdown_isd_search__NynyB:focus {
    outline: 0;
    box-shadow: none;
  }
  .country_mob_new :global .theme5mobile_modal_container__LSp2Y .IsdCodeDropdown_isd_search__NynyB {
    height: 41px;
  }

  .personal_login_text div {
    width: 100%;
    text-align: center;
  }

  .login_by_mail_id_text {
    font-family: var(--font_family);
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: left;
    color: var(--primary_color);
  }

  .login_via_otp {
    width: 101px;
    height: 16px;
    margin: 2px 67px 44px 40px;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: left;
    color: var(--primary_color);
  }

  .email_id:focus {
    box-shadow: none;
    outline: none;
    border-color: #c3c3c3;
  }

  .email_id {
    width: 100%;
    height: 55px;
    border-radius: 5px;
    margin: 5px 0 2px;
    padding: 14px !important;
    border: 1px solid #c3c3c3 !important;
    background-color: #fff;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: .38px;
    text-align: left;
    color: var(--font_color);
    margin-right: 0 !important;
  }

  .email_id::placeholder {
    font-family: var(--font_family);

    font-size: 15px;

    font-weight: normal;

    font-stretch: normal;

    font-style: normal;

    line-height: normal;

    letter-spacing: 0.38px;

    text-align: left;

    color: var(--font_color);
  }

  .email_id:disabled {
    background: #f0eeee;
    color: var(--font_color);
  }

  .forgot_password {
    margin: 20px 0 0;
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: left;
    color: var(--primary_color);
    cursor: pointer;
  }

  .viaotp {
    text-decoration: underline;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;

    line-height: normal;

    letter-spacing: 0.38px;

    text-align: left;

    color: var(--primary_color);
    font-family: var(--font_family);
    margin-top: 2px;
  }

  .new {
    font-size: 8px;

    font-weight: normal;

    font-stretch: normal;

    font-style: normal;

    line-height: normal;

    letter-spacing: 0.2px;

    text-align: left;

    color: #fff;
    font-family: var(--font_family);
    padding: 2px 4px 2px 5px;

    border-radius: 3.2px;

    background-color: var(--primary_color);
  }

  .btn_login {
    font-size: 15px;
    border-radius: 10px;

    font-weight: normal;

    font-stretch: normal;

    font-style: normal;

    line-height: normal;

    letter-spacing: 0.63px;

    text-align: center;

    color: #fff;
    height: 52px;

    margin: 30px 0;

    padding: 13px;
    width: 100%;

    background-color: var(--buttons_color);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 70px;
  }

  .or_login_with {
    margin: 0 0 19px 0;

    font-family: var(--font_family);

    font-size: 15px;

    font-weight: normal;

    font-stretch: normal;

    font-style: normal;

    line-height: normal;

    letter-spacing: 0.38px;

    text-align: center;

    color: var(--font_color);
    position: relative;
  }

  .or_login_with::before {
    content: "";
    height: 1px;
    width: 40%;
    background: #e6e6e6;
    position: absolute;
    left: 5%;
    top: 7px;
  }

  .or_login_with::after {
    content: "";
    height: 1px;
    width: 40%;
    background: #e6e6e6;
    position: absolute;
    right: 5%;
    top: 7px;
  }

  .noaccount {
    margin: 25px 0 10px 0;

    font-family: var(--font_family);

    font-size: 15px;

    font-weight: normal;

    font-stretch: normal;

    font-style: normal;

    line-height: normal;

    letter-spacing: 0.38px;

    text-align: center;

    color: var(--font_color);
  }

  .noaccount span,
  .policytext span {
    color: var(--primary_color);
    cursor: pointer;
  }

  .policytext {
    margin: 23px 0 0;

    font-family: var(--font_family);

    font-size: 15px;

    font-weight: normal;

    font-stretch: normal;

    font-style: normal;

    line-height: 25px;

    letter-spacing: 0.3px;

    text-align: left;

    color: var(--font_color);
  }
  .policytext {
    text-align: center;
    width: 90%;
    margin: 0 auto;
  }
  .personal_login_tab_active {
    padding: 11px 15px 11px 15px;

    background-color: var(--primary_color);
    font-size: 14px;

    font-weight: normal;

    font-stretch: normal;

    font-style: normal;

    line-height: normal;

    letter-spacing: 0.45px;

    text-align: center;

    color: #fff;
    font-family: var(--font_family);

    border-radius: 20px;
  }

  .singup_header {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: left;
    width: 100%;
    color: var(--primary_color);
    font-family: var(--font_family);
    margin: 20px 0 0;
    padding: 0px;
  }

  .back {
    font-size: 15px;

    font-weight: normal;

    font-stretch: normal;

    font-style: normal;

    line-height: normal;

    letter-spacing: 0.38px;

    text-align: left;

    color: var(--primary_color);
    font-family: var(--font_family);
    margin: 7px 0 24px;
    cursor: pointer;
  }

  .otp_to_email {
    margin: 8px 0 10px 0;

    font-family: var(--font_family);

    font-size: 15px;

    font-weight: normal;

    font-stretch: normal;

    font-style: normal;

    line-height: normal;

    letter-spacing: 0.38px;

    text-align: left;

    color: var(--font_color);
  }

  .otp_to_email span {
    color: var(--primary_color);
  }

  .resend_otp {
    font-size: 13px;

    font-weight: normal;

    font-stretch: normal;

    font-style: normal;

    line-height: normal;

    letter-spacing: 0.33px;

    text-align: left;

    color: var(--primary_color);
    position: absolute;
    right: 15px;
    top: 58%;
    z-index: 9;
    cursor: pointer;
  }

  .pswd_text {
    margin: 7px 0 17px 0;

    font-family: var(--font_family);

    font-size: 14px;

    font-weight: normal;

    font-stretch: normal;

    font-style: normal;

    line-height: 1.79;

    letter-spacing: 0.35px;

    text-align: left;

    color: var(--font_color);
  }

  .pswd_success {
    font-family: var(--font_family);
    margin: 18px 0 15px;

    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;

    text-align: center;

    color: #32a709;
  }

  .error_class .email_error_msg {
    margin:0;

    font-family: var(--font_family);

    font-size: 12px;

    font-weight: normal;

    font-stretch: normal;

    font-style: normal;

    line-height: normal;

    letter-spacing: 0.3px;

    text-align: left;

    color: red;
    position: relative !important;
    width: auto !important;
  }

  .back_to_login {
    margin: 48px 0;

    font-family: var(--font_family);

    font-size: 16px;

    font-weight: normal;

    font-stretch: normal;

    font-style: normal;

    line-height: normal;

    letter-spacing: 0.5px;

    text-align: center;

    color: var(--primary_color);
    cursor: pointer;
  }

  .link_to_email {
    margin: 9px 0px 45px 0px;

    font-family: var(--font_family);

    font-size: 15px;

    font-weight: normal;

    font-stretch: normal;

    font-style: normal;

    line-height: normal;

    letter-spacing: 0.38px;

    text-align: left;

    color: var(--font_color);
  }

  .valid_link {
    margin: 45px 0 0 0;

    font-family: var(--font_family);

    font-size: 13px;

    font-weight: normal;

    font-stretch: normal;

    font-style: normal;

    line-height: normal;

    letter-spacing: 0.33px;

    text-align: left;

    color: var(--font_color);
  }

  .valid_link span {
    color: var(--primary_color);
  }

  .accept_policy {
    margin: 16px 0 0 0px;
    display: flex;

    font-family: var(--font_family);

    font-size: 15px;

    font-weight: normal;

    font-stretch: normal;

    font-style: normal;

    line-height: normal;

    letter-spacing: 0.38px;

    text-align: left;
    position: relative;
    color: var(--font_color);
  }

  /* The checkbox_common */
  .checkbox_common {
    display: inline-flex;
    position: relative;
    height: 20px;
    width: 20px;
    margin-right: 20px;
  }

  /* Hide the browser's default checkbox */
  .checkbox_common input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    cursor: pointer;
    border-radius: 3px;
    border: 1px solid #888;
  }

  /* When the checkbox is checked, add a blue background */
  .checkbox_common input:checked ~ .checkmark {
    background-color: var(--secondary_color);
    border: 1px solid var(--secondary_color);
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox_common input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox_common .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .cong_text {
    margin: 19px 0 45px 0;

    font-family: var(--font_family);

    font-size: 15px;

    font-weight: normal;

    font-stretch: normal;

    font-style: normal;

    line-height: normal;

    letter-spacing: 0.38px;

    text-align: center;

    color: var(--font_color);
  }

  .cong_text span {
    color: var(--primary_color);
    font-weight: bold;
  }

  .country_drp {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .country_mob_new{
    align-items: flex-start;
  }
  .input_newcont .error_class {
    width: auto;
    margin-top: 10px;
}
.singup_header .email_id:disabled {
  padding-left: 20px;
  margin-top: 10px !important;
}
.new_cont label {
  color: var(--secondary_color);
  font-size: 15px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin-bottom: 0;
}
.zenith_input.zenith_c_password input, .zenith_password input {
  margin-top: 10px;
}
.input_newcont, .new_cont, .zenith_c_password, .zenith_password{
  margin-top: 10px;
}
.zenith_input_isd :global .theme5_vt_country_select_list_container__xCTZf{
  position: absolute;
  top: 52px;
}
  .country_drp > div:first-child {
    width: 33% !important;
  }

  .country_drp > div:last-child {
    width: 65%;
  }

  .SignUpSuccessful_mob {
    text-align: center;
  }

  .SignUpSuccessful_mob img {
    display: inline-block;
    vertical-align: middle;
  }

  .SignUpSuccessful_mob button.continue_btn {
    font-size: 16px;
    letter-spacing: 0.63px;
    text-align: center;
    height: 45px;
    margin: 15px 0px;
    padding: 13px;
    /* width: 74%; */
    background-color: var(--buttons_color);
    /* display: flex; */
    align-items: center;
    /* justify-content: center; */
    color: #ffff;
  }

  .SignUpSuccessful_mob .SignUpSuccessful_inner {
    font-size: 16px;
    margin-top: 10px;
  }

  .SignUpSuccessful_inner {
    display: block;
  }

  .SignUpSuccessful_inner span {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 25px;
    letter-spacing: 0.4px;
    text-align: center;
    color: #0ead0b;
  }
  .congrats {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 25px;
    letter-spacing: 0.4px;
    text-align: center;
    color: #0ead0b;
  }
  .cont_btn {
    width: 80%;
    height: 45px;
    margin: auto;
    background: var(--buttons_color);
    color: #fff;
    margin-top: 30px;
    border-radius: 10px;
    display: block;
  }

  .tick_img {
    width: 50px;
    height: 50px;
    margin: 30px auto;
  }

  .SignUpSuccessful_mob .sucess_signup {
    margin-bottom: 10px;
    font-size: 21px;
  }

  .sucess_signup {
    font-family: var(--font_family);
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.55px;
    color: var(--primary_color);
    text-align: center;
  }

  .zenith_signin {
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    height: 70%;
    padding: 0 15px;
    padding-top: 50px;
    width: 100%;
    overflow: auto;
    padding-bottom: 30%;
  }

  .heading_text_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding-top: 20px; */
    /* background: red; */
    /* height: 150px; */
    padding: 15px 15px 15px 15px;
    gap: 25px;
    width: 100%;
    /* padding-top: 10px; */
  }

  .login_by_mail_id_text {
    color: #fff !important;
    font-size: 38.5px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.96px;
    text-align: left;
  }
  .login_by_mail_id_text .password_update_text{

  }
  .zenith_input {
    padding: 10px 0;
    margin-bottom: 0px;
  }

  .zenith_input label {
    color: var(--secondary_color);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
  }
  .approved_sec_mob {
    display: flex;
    align-items: center;
    gap: 0px;
    color: #fff;
    font-size: 20px;
    padding: 20px 20px 10px;
  }
  .approved_sec_mob svg{
    margin-right: 16px;
  }
  .approved_sec_mob p{
    font-family: var(--font_family);
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    color: #fff;
    width: 100%;
  }
  .approved_sec {
    display: none;
  }

  .welcome_zenith svg {
    width: 40px;
    height: 40px;
    display: block;
    margin: 0 auto;
  }

  .welcome_zenith {
    color: white;
    padding: 25px 15px;
    display: block;
    padding-top: 0px;
    background-color: transparent;
  }

  .welcome_zenith p {
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: 0.43px;
    text-align: center;
    display: block;
    color: #ffffff;
  }

  .input_flex {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    width: 100%;

  }

  .zenith_signin_form {
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    /* height: 65%; */
    height: 87%;
    padding: 0 15px;
    padding-top: 20px;
    width: 100%;
    overflow: auto;
    padding-bottom: 30px;
  }

  .pwd_info {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    text-align: left;
    color: var(--font_color);
    opacity: 0.7;
    padding-top: 15px;
  }

  .do_later {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
  }

  .form_heading_text {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: left;
    color: var(--font_color);
    padding:  1px 0;
  }

  .passowrd_instr {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    text-align: left;
    color: var(--font_color);
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .modal_content {
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    margin: auto;
    z-index: 2;
    max-width: 550px;
    color: var(--primary_color);
    font-family: var(--font_family);
    font-size: 12px;
    padding: 20px 70px;
    padding: 10px !important;
    /* border-radius: 10px; */
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    bottom: 0;
  }
  .modal_content.mobile_modal {
    padding: 0px 0px;
    border-radius: 0px;
  }
  .modal_overflow {
    padding: 0px 0;
    position: absolute;
    top: 0;
    width: 100%;
    background: transparent;
    left: 0;
  }

  .modal_close {
    position: absolute;
    right: 13px;
    top: 20px;
    width: 30px;
  }

  .email_headinga_text {
    color: var(--primary_color);
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: 0.55px;
    text-align: center;
    margin-top: 50px;
  }

  .modb_icon svg {
    width: 100%;
    margin-top: 20px !important;
    margin: auto;
  }

  .modb_title {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 0.45px;
    text-align: center;
    color: var(--font_color);
    margin-top: 30px;
  }

  .ok_btn {
    width: 114px;
    height: 44px;
    background: var(--buttons_color);
    font-family: var(--font_family);
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.43px;
    text-align: center;
    color: #fff;
    margin: auto;
    border-radius: 10px;
    display: block;
    margin-top: 36px;
  }

  .email_id:focus + .special_character {
    display: block !important;
  }

  .special_character {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.35px;
    text-align: left;
    color: #fff;
    position: absolute;
    background: #0ead0b;
    padding: 10px;
    top: 45px;
    z-index: 1;
    display: none;
  }

  /* .special_character::before {
    content: "";
    -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    background: #0ead0b;
    position: absolute;
    top: -12px;
    left: 14px;
    z-index: 1;
    width: 15px;
    height: 13px;
  } */

  .flex_password {
    position: relative;
    display: initial !important;
  }

  .checkbox_label {
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative !important;
    margin: 5px 0;
    top: 0 !important;
  }

  .checkbox_label input {
    width: 24px;
    height: 24px;
    border-radius: 2px;
    border: solid 1px #dedddd;
  }

  .checkbox_label label {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: left;
  }

  .succ_pass {
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    height: 47%;
    padding: 0 15px;
    padding-top: 35px;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pass_heading {
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: 0.55px;
    text-align: left;
    color: var(--primary_color);
  }

  .succ_pass_img {
    width: 65px;
    height: 65px;
    margin: 20px 0;
  }

  /* .vt_country_select_main_container_new .vt_country_select_container{
    background: red;
  } */

  .Social_login {
    display: flex;
    justify-content: center;
  }
  .Social_login button {
    background-color: transparent;
    border-color: transparent;
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    height: auto;
  }
  .Social_login button img {
    width: auto;
    height: auto;
  }
  .login_banner {
    display: none;
  }
  .newercontent {
    display: block;
  }
  .deskshow {
    display: none;
  }
  .newflex {
    display: none;
  }
  .form_flex {
    padding: 0px;
    width: 100%;
  }
  .desktopdiv {
    display: none;
  }
  .zenith_signin {
    background: #fff;
    position: absolute;
    bottom: 0;
    top: 160px;
    left: 0;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    /* height: 100%; */
    /* height: 74%; */
    height: auto;
    padding: 0 15px;
    padding-top: 30px;
    width: 100%;
    overflow: auto;
    padding-bottom: 50px;
  }

  .zenith_last_name label {
    padding-left: 0px;
  }
  .marginlr {
    margin-left: 0px;
  }
  .dropdown_section .email_id {
    margin: 0px;
  }
  .country_drp.dropdown_section > div:last-child {
    width: 68%;
    margin-left: 8px;
  }
  .isdlabel {
    margin-bottom: 5px;
    display: block;
  }
  .mobisd {
    margin-bottom: 5px;
    display: block;
  }
  .zenith_input_isd span {
    padding-left: unset !important;
  }
  .zenith_input_isd{
    position: relative;
  }
  .email_error_msg_isd{
    bottom: 0px;
    margin: 5px 0 0px;
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: left;
    color: red;
    position: absolute;
  }
  .SignUpSuccessful {
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    height: 70%;
    width: 100%;
    overflow: auto;
    padding: 50px 15px 10%;
  }
  .SignUpSuccessful img {
    width: 56px;
    height: 56px;
    margin: 30px auto;
  }
  .SignUpSuccessful button {
    width: 80%;
    height: 45px;
    background: var(--buttons_color);
    color: #fff;
    margin: 30px auto auto;
    border-radius: 10px;
    display: block;
  }
  .invalidmodal_wrap{
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: max-content;
    padding: 15% 5%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    text-align: center;
  }
  .inv_heading{
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: .55px;
    color: var(--secondary_color);
  }
  .inv_title{
    font-size: 14px;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: .35px;
    color: var(--font_color);
  }
  .button_wrap .reset_btn{
    height: 52px;
    padding: 10px 45px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.1;
    letter-spacing: .5px;
    background: var(--buttons_color);
    color: white;
    text-align: center;
  }
 
  .mob_new label {
    color: var(--secondary_color);
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
  }
  .modalb_wrap {
    padding: 20px 16px;
    margin-top: 10px;
  }
  .deskicon{
    display: none;
  }
  .mobicon{
    display: block;
    padding-bottom: 15px;
  }
  .zenith_password{
    position: relative;
  }
  .zenith_c_password{
    position: relative;
  }
  .login_by_mail_id_text.password_update_text {
    font-family: var(--font_family);
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: 0.55px;
    text-align: center;
    color: var(--primary_color) !important;
    justify-content: center;
}
 
.change_password_main img{
  width: 60px;
  margin: 20px auto;
}
.modalb_wrap.change_password_main{
  padding: 30px 20px 0px;
  text-align: center;
  width: auto;
} 
.change_password_main .btn_login {
  margin-bottom: 0px;
}
.signup_main_container :global .modal-overflow{
  height: auto;
}

.signup_main_container :global .modal-content{
  height: 100vh;
  overflow-y: unset;
}
}

.inv_heading{
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: .55px;
  color: var(--secondary_color);
  text-align: center;
  padding-top: 40px;

}
.inv_title{
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: .35px;
  color: var(--font_color);
  width: 90%;
  margin: auto;
  text-align: center;
}
.reset_btn{
  height: 52px;
  padding: 10px 45px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.1;
  letter-spacing: .5px;
  background: var(--buttons_color);
  color: white;
  text-align: center;
  text-align: center;
  margin: 20px auto;
  display: block;
  
}

@media screen and (min-width: 330px) and (max-width: 390px) {
  .login_by_mail_id_text{
    font-size: 26px;
  }
}
.crossicon svg {
  height: 25px;
  color: #919191;
  border-radius: 50px;
  position: absolute;
  z-index: 3;
  top: 10px !important;
  right: 20px;
  width: 25px;
  border: 1px solid #919191;
  padding: 3px;
  cursor: pointer;
}
