.new_header {
  position: fixed;
  box-shadow: 0px 4px 5px 0 rgb(0 0 0 / 10%);
  background: #fff;
  width: 100%;
  height: 75px;
  top: 0px;
  padding: 0 10px;
}
.main_logo_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* position: fixed; */
  z-index: 999;
  background: var(--primary_color);
  width: 100%;
  display: flex;
  padding: 10px;
  /* left: 0;
    right: 0;
    top: 0; */
  box-shadow: 0px 4px 5px 0 rgb(0 0 0 / 10%);
  height: 65px;
}

.header_sections input[type="image"],
.header_sections img {
  height: 23px;
  width: 23px;
  object-fit: scale-down;
  filter: invert(1);
}

.logo_header {
  display: flex;
  align-items: center;
}
.logo_header img {
  height: 18px;
  width: 18px;
  object-fit: scale-down;
}
.login_btn button {
  font-family: var(--font_family);
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #ffffff;
}

.login_btn input,
.login_btn img {
  height: 17px;
  width: 17px;
  margin-right: 8px;
  object-fit: scale-down;
}
.logosub_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* position: relative; */
  /* margin-left: 25px; */
}

.support_btn input,
.support_btn img {
  height: 23px;
  width: 23px;
  object-fit: scale-down;
  margin-right: 8px;
}

.header_all_section {
  padding: 14px 25px 8px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-evenly;
  background-color: var(--primary_color);
  margin: 4px 0;
  border-radius: 10px 10px 0px 0px;
  height: 62px;
}

.header_all_section .header_sections:last-child {
  margin-right: 0;
}

.header_section {
  padding-top: 0px;
  position: fixed;
  z-index: 999;
  background: #fff;
  width: 100%;
  height: 70px;
  box-shadow: 0px 4px 5px 0 rgb(0 0 0 / 10%);
  left: 0;
  right: 0;
  top: 0;
}
.logosub_header img {
  /* margin: 0 5px; */
  height: 28px;
  width: 28px;
}
.profile_details_hide {
  display: none;
}

.show {
  position: absolute;
  width: 200px;
  object-fit: contain;
  box-shadow: 0 0 5px 2px rgb(0 0 0 / 15%);
  background-color: #ffffff;
  right: -5px;
  top: 50px;
  padding: 10px;
  user-select: none;
  text-align: left;
  z-index: 99;
}

.afterlogin {
  display: flex;
  align-items: center;
  position: relative;
}

.afterlogin > span:first-child {
  background: #fff;
  height: 49px;
  width: 49px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary_color);
  font-size: 22px;
  font-weight: bold;
  margin: 0 5px;
  letter-spacing: 0.55px;
}

.afterlogin > span img {
  height: 15px;
  width: 15px;
  object-fit: scale-down;
  cursor: pointer;
}

.show > span {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: normal;
  margin: 12px 0;
  cursor: pointer;
  color: var(--font_color);
}

.show > span:hover {
  font-weight: bold;
  color: var(--primary_color);
}

.show > span img {
  height: 25px;
  width: 25px;
  /* object-fit: scale-down; */
  margin-right: 12px;
}

.navbar_right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  /* margin-right: 20px; */
}
.navbar_right .dropdown .dropdown_span {
  color: #303030;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 1rem;
  display: block;
  display: flex;
  align-items: center;
  /* border-right: 1px solid #696969; */
  color: #696969;
  font-family: var(--font_family);
  font-size: 12px;
  padding: 0 0 0 5px;
}
.navbar_right .dropdown .dropdown_span svg {
  display: inline;
}
.navbar_right .dropdown:last-child a {
  border-right: 0;
}
.flag {
  width: 25px;
  height: 25px;
  float: none;
  margin: 0 5px 0 0;
  display: inline;
  margin-top: -4px;
}
.drop_arrow {
  display: inline;
  margin-left: 18px;
  width: 15px;
  margin-top: -3px;
}

.welcome_text {
  color: #fff;
  font-size: 18px;
  font-family: var(--font_family);
}

.znth_main {
  color: #fff;
  padding-left: 10px;
  display: flex;
  /* gap: 5px; */
  font-size: 15px;
}

.divider_line {
  font-size: 15px;
  margin: 0 5px;
  position: relative;
}

.language_dropMain {
  position: absolute;
  background: white;
  top: 80%;
  right: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.lang_title {
  color: var(--primary_color);
  padding-bottom: 10px;
  font-weight: 600;
}

.language_wrap {
  padding-bottom: 10px;
}

.lang_label {
  padding-left: 5px;
}

.apply_btn button {
  width: 100%;
  padding: 7px 10px;
  background: var(--primary_color);
  color: #fff;
  border-radius: 10px;
}
.login_btn svg {
  width: 35px;
  height: 35px;
}
.drop_arrow {
  font-weight: 700;
  color: #fff;
  margin-left: -1px;
  font-size: 25px !important;
  display: inline-block;
  margin-top: -2px;
  line-height: 1;
}
.header_stickyMobile {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  transition: padding-top 0.5s ease;
}
.support {
  display: inline-block;
}
.dropZ_arrow {
  display: flex;
  align-items: center;
}
.dropZ_arrow span {
  color: #fff;
}
.znth_support {
  display: flex;
  align-items: center;
  margin-right: 0px;
  cursor: pointer;
}
.znth_support span {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin-left: 5px !important;
}
.header_menuZth {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
}
.login_button {
  font-size: 14px;
  padding: 7px 15px;
  border-radius: 4px;
  background-color: var(--buttons_color);
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.suport_cont {
  position: relative;
  display: flex;
  align-items: center;
}

.supportdropNew {
  position: absolute;
  width: 290px;
  border-radius: 4px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e6e7e8;
  background-color: #ffffff;
  right: 0;
  top: 40px;
  padding: 20px 15px;
  padding-bottom: 0px;
  text-align: right;
  z-index: 9;
}

header.mobile-header.theme5 {
  position: sticky;
  top: -2px;
  z-index: 99;
}
.translate_element span a {
  display: none;
}


.translate_element select,
.translate_element select option {
  cursor: pointer;
}
.translate_element select option {
  font-size: 14px;
  font-weight: normal;
  color: var(--secondary_color);
  padding: 10px;
  appearance: none;
  font-family: var(--font_family);
}
.translate_element {
  margin-top: 17px;
  position: relative;
}
.translate_element select::-webkit-scrollbar {
  width: 3px;
}
.translate_element select::-webkit-scrollbar-thumb {
  background-color: #999;
}
.translate_element select::-webkit-scrollbar-track {
  background-color: #ddd;
}
.translate_element .skiptranslate {
  position: relative;
}
.translate_element > div > div > div::before {
  content: ".";
  position: absolute;
  width: 72px;
  bottom: 2px;
  background-color: #266aac;
  z-index: 9;
  height: 14px;
  color: #266aac;
}
.translate_element select {
  border: none;
  width: 138px;
  padding: 0px;
  font-size: 13px;
  font-weight: bold;
  color: var(--secondary_color);
  appearance: none;
  background-image: url("/images/dropheader.svg");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 7px auto;
  padding: 0px 5px;
  font-family: var(--font_family);
}
.translate_element select:focus {
  border: none;
  box-shadow: none;
}
.translate_element select option:first-of-type {
  font-size: 18px;
  font-weight: bold;
  color: var(--secondary_color);
}

.znth_main span svg{
  filter: invert(1);
}

.translate_modal :global .modal-content{
  background: #fff!important;
  border-radius: 0px;
}

.footer_contact{
  background: #ECECEC;
  padding: 13px 10px;
}
.footer_contact p{
  color: #4B4B4B;
font-family: var(--font_family);
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom: 12px;
}
.flex_row{
  display:flex;
  gap: 18px;
}
.footer_contact span{
  display: flex;
  align-items: center;
  gap: 10px;
  color: #4B4B4B;
font-family: var(--font_family);
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.header_blueband {
  background-color: var(--primary_color);
  height: 65px;
  width: 100%;
    text-align: center;
    position: relative;
    padding: 20px;
  
}
.header_blueband span{
  color: #FFF;
font-family: var(--font_family);
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-align: center;
padding-bottom: 12px;
display: block;

}


.header_blueband svg{
  position: absolute;
  top: 20px;
  left: 10px;
  z-index: 9;
}