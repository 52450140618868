
/* start ---translatelanguage-- */

.translatelanguage{
    width: 312px;
    min-height: 212px;
    max-height: 252px;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 80px;
    padding: 11px 15px;
    left: -80px;
  }
  .translatelanguage::before{
    content: "";
      display: block;
      position: absolute;
      left: calc(50% - -33px);
      bottom: 100%;
      width: 0;
      height: 0;
      border: 11px solid transparent;
      border-bottom-color: #e2e2e2;
  }
  .translatelanguage::after{
    content: "";
      display: block;
      position: absolute;
      left: calc(50% - -34px);
      bottom: 100%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom-color: white;
  }
  
  .translatelanguage .translatelanguage_row1{
    border-bottom: 1px solid #D4D4D4;
    padding-bottom: 15px;
    margin-bottom: 11px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
   .translatelanguage h6{
    color: var(--font_color);
    font-family: var(--font_family);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 6px;
  }
  .translatelanguage .translatelanguage_box{
    border-radius: 5px;
    background: #ECECEC;
    width: 135px;
    height: 34px;
    display: flex;
    align-items: center;
    padding: 0px 6px;
    gap: 5px;
  }
  
  .translatelanguage .translatelanguage_box{
    color: var(--font_color);
    font-family: var(--font_family);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .translatelanguage .translatelanguage_row2 label{
    border-radius: 5px;
    border: 1px solid #D4D4D4;
    background-color: #ffffff;
    color: var(--font_color);
    font-family: var(--font_family);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 130px;
    height: 34px;
    padding: 7px 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .translatelanguage .translatelanguage_row2 label input{
    margin-right: 5px;
  }
  .translatelanguage .translatelanguage_row2 label input:checked {
    outline: 1px solid var(--primary_color);
    outline-offset: 2px;
    background-image: none;
    width: 12px;
    height: 12px;
    left: 1px;
    top: 0;
    position: relative;
    margin-right: 9px;
  }
  .translatelanguage .translatelanguage_row2 label input[type=radio]{
  color: var(--primary_color) ;
  border-color: var(--primary_color);
  }
  
  .translatelanguage .translatelanguage_row2 label.translatelanguage_active{
    background-color: #DEF1FA;
  }
  .translatelanguage .translatelanguage_overflow{
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    overflow-y: auto;
    max-height: 119px;
  
  }

  
  /* width */
  .translatelanguage_overflow::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .translatelanguage_overflow::-webkit-scrollbar-track {
    background: #ECECEC;
  }
  /* Handle */
  .translatelanguage_overflow::-webkit-scrollbar-thumb {
    background: #3B79B4;
  }
  
  /* Handle on hover */
  .translatelanguage_overflow::-webkit-scrollbar-thumb:hover {
    background: #3B79B4;
  }
  
  /* end ---translatelanguage-- */

  @media screen and (max-width: 767px) and (min-width: 320px) {
    .translate_button{
      border-radius: 10px;
      background: var(--buttons_color);
      color: #FFF;
      font-family: var(--font_family);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      width: 100%;
      height: 43px;
    }
    .translatelanguage{
      box-shadow: none;
      position: unset;
      width: 100%;
      height: auto;
      margin-top: 18px;
      margin-bottom: 38px;
      padding: 11px 10px;
      max-height: 100%;
    }
    .translatelanguage .translatelanguage_row1{
      display: block;
    }
    .translatelanguage .translatelanguage_box{
      width: 100%;
      height: 43px;
    }
    .translatelanguage_row1 div:first-child{
          margin-bottom: 27px;
    }
    .translatelanguage .translatelanguage_row2 label{
      height: 43px;
      width: 48%;
      padding-left: 10px;
    }
    .translatelanguage .translatelanguage_overflow{
      max-height: 198px;
      margin-bottom: 40px;
    }







  }
  
  