.isd_dropdown {
    position: relative;
    width: 100%;
    width: 90px;
  }
  
  .isd_label {
    display: block;
    margin-bottom: 2px;
    font-size: 14px;
    color: #333;
  }
  
  .isd_input_wrapper {
    position: relative;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    display: flex;
    align-items: center;
  }
  
  .isd_input {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    cursor: pointer;
    /* border: solid 1px #cacaca; */
    border-radius: 4px;
    height: 43px;
    border: 0;
    padding: 0;
  }
  
  /* .isd_input input {
    border: none;
    outline: none;
    width: 100%;
    font-size: 14px;
  } */
  
  .isd_input img.isd_flag {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    /* border: 1px solid #ddd; */
    border-radius: 2px;
    z-index: 1;
  }
  
  .isd_input .isd_code {
    /* margin-left: 8px; */
    color: #555;
    font-size: 14px;
  }
  
  .isd_dropdown_menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 1000;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .isd_search {
    border-radius: 5px;
    border: solid 1px #cacaca !important;
    color: var(--font_color);
    font-family: var(--font_family);
    font-size: 15px;
    font-size: revert !important;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    height: 41px;
    padding: 10px !important;
    width: 100%;
    letter-spacing: normal;
    position: absolute;
    padding-left: 27px !important;
  }
  .isd_search::placeholder {
    color: var(--font_color) !important;
    font-family: var(--font_family);
    font-weight: 500;
    font-size: revert !important;
  }
  
  .isd_suggestions {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 100;
    top: 45px;
    max-height: 180px;
    padding: 0 0 15px;
    border: 1px #e2e0e0 solid;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #fff;
    width: 300px !important;
    max-width: 300px;
    padding-bottom: 0px;
    padding-right: 5px;
    padding-left: 0px;
  }

  .isd_suggestions::-webkit-scrollbar{
    width: 5px;
    background: #cccccc8e;
  }
  .isd_suggestions::-webkit-scrollbar-thumb{
    width: 5px;
    background: var(--primary_color);
  }
  
  .isd_suggestion{
    font-size: 14px;
    display: flex;
    padding: 12px 6px;
    gap: 5px;
    cursor: pointer;
    position: relative;
    align-items: center;
  }

  .isd_suggestion_item {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .isd_suggestion:hover {
    background-color: #f7f7f7;
  }

  .isd_drop_new{
    position: absolute;
    top: 20px;
    right: 7px;
  }
  
  .isd_flag {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  
  .isd_suggestion_item .isd_name {
    flex: 1;
    color: #333;
    font-size: 14px;
  }
  .isd_code_spc{
    margin-left: auto;
  }
  
  .isd_suggestion_item .isd_code {
    margin-left: 8px;
    color: #555;
    font-size: 14px;
  }
  
  .isd_suggestion_item .isd_country_code {
    margin-left: 8px;
    color: #999;
    font-size: 12px;
  }

  .isd_suggestions .isd_code,
.isd_suggestions .isd_name{
    color: var(--font_color);
    font-size: 14px;
    line-height: normal;
    font-weight: 500;
}
.isd_input_wrapper .isd_search{
  padding-left: 10px !important;
  cursor: pointer;
}
.no_result {
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
  padding: 10px;
}
  
  @media screen and (min-width: 320px) and (max-width: 768px){
    .no_result_found {
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }
    
    .existing_traveller_panel {
      position: absolute;
      z-index: 100;
      top: 41px;
      width: 100%;
  }
  .no_result {
    font-family: var(--font_family);
    font-size: 15px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: var(--font_color);
    padding: 10px 20px;
    position: relative;
    z-index: 999;
  }
  .isd_dropdown{
    /* width: 100%; */
    width: 100px;
  }
  .Result_search_box.country_pop.result_country_code {
      top: 0;
      width: 320px;
      max-height: 170px;
      padding: 15px;
      padding-top: 0;
  }
  .Result_search_box.country_pop.result_country_code span img {
      width: 38px;
      display: inline;
  }
  .Result_search_box.country_pop.result_country_code {
      position: absolute;
      width: 256px;
      max-height: 180px;
      background-color: #fff;
      z-index: 10;
      scroll-behavior: smooth;
      overflow-x: hidden;
      overflow-y: scroll;
      top: 1px;
      left: 0;
      border: 1px solid #e2e0e0;
      border-top: none;
      padding-bottom: 0;
      padding-left: 6px;
      padding-top: 5px;
  }
  .Result_search_box.country_pop.result_country_code ul li {
      margin-bottom: 10px;
  }
  .Result_search_box.country_pop.result_country_code ul li img {
      width: 20px;
      display: inline;
      height: 20px;
      margin-right: 5px;
  }
  .Result_search_box.country_pop.result_country_code ul li span {
      color: var(--font_color);
      font-size: 14px;
      font-family: var(--font_family);
      margin: 0 3px;
  }
  .Result_search_box.country_pop.result_country_code::-webkit-scrollbar {
      width: 5px;
  }
  .Result_search_box.country_pop.result_country_code::-webkit-scrollbar-track {
      background: #f1f1f1;
  }
  .Result_search_box.country_pop.result_country_code::-webkit-scrollbar-thumb {
      background: #888;
  }
  .Result_search_box.country_pop.result_country_code::-webkit-scrollbar-thumb:hover {
      background: #555;
  }
      .Result_search_box.country_pop.result_country_code {
          position: absolute;
          width: 100%;
          top: 97px;
          max-height: none;
          border: none;
          height: 650px;
      }
      .Result_search_box.country_pop.result_country_code ul li {
          position: relative;
          padding: 10px 5px 15px;
          border-bottom: 1px solid #dedede;
      }
      .Result_search_box.country_pop.result_country_code ul li span {
          position: absolute;
          right: 0;
          color: var(--font_color);
          font-weight: 400;
          font-size: 13px;
          color: var(--font_color);
          top: 15px;
      }
      .Result_search_box.country_pop.result_country_code ul li span + span {
          position: unset;
          right: unset;
          font-size: 17px;
          font-family: var(--font_family);
          margin: 0 3px;
          font-weight: 600;
      }
      .Result_search_box.country_pop.result_country_code ul li img {
          margin-right: 10px;
          width: 30px;
          position: static;
      }
      .width20 {
        width: 20px;
        height: 20px;
    }
        .maininput {
            padding: 12px 20px 12px 19px;
            background-color: #1b5d9d;
            position: relative;
        }
        .maininput svg {
            position: absolute;
            top: 24px;
            left: 26px;
        }
        .maininput input {
          border-radius: 5px !important;
          background-color: #ebebeb !important;
          width: 100% !important;
          font-size: 14px;
          position: static;
          height: auto;
        }
        .maininput input::placeholder {
            font-family: var(--font_family);
            font-size: 15px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.38px;
            text-align: left;
            color: #979797;
        }
        .header_text {
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          text-align: left;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding-left: 0;
        }
  
  }
  .isd_input_container_disabled{
    pointer-events: none;
    }