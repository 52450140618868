.mobile_header_logoContainer{
    padding: 0px;
}
.mobile_header_logoWrap{
    display: flex;
    height: 77px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFF;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
    position: fixed;
    /* top: 50px; */
    left: 0;
    right: 0;
    z-index: 99;
    width: 100%;
    transform: translateY(0px); /* Start from 50px */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    
}
.header_logo{
    width: 100%;
    height: auto;
}
.header_sticky_mobile {

    /* position: fixed; */
    /* top: 0;  */
    transform: translateY(-50px); /* Moves to the top smoothly */
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1); /* Softer shadow when sticky */ 
  
  
}
    