.navigation{
    width: 100%;
    height: 50px;
}


.navigation ul{
    display:flex;
    flex-wrap: wrap;
    float: right;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
}
.navigation ul li{
    list-style-type: none;
    padding-right: 10px;

}



.hamburger{
    display: none;
    z-index: 6;
} 

.mobile_sidenav{
    border-style: none;
    display: flex;
    color: var(--font_color);
    font-size: 15px;
    letter-spacing: 0.38px;
    overflow: hidden;
    padding: 16px;
    text-decoration: none;
    height: 100%;
    box-sizing: border-box;
    cursor: pointer;
    gap: 10px;
}
 .mobile_sidenav:hover {
   color: var(--primary_color);
    font-weight: bold;
  }
  .mobile_sidenav:hover .bui_u_margin8 {
   color: var(--primary_color);
    background-color: #256380;
  }
   .mobile_sidenav:hover img {
    color: #fff;
    filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(138deg) brightness(103%) contrast(220%);
  }
.my_settings_section_card_icon{
    background-color: #e7e7e7;
      box-sizing: border-box;
      border-radius: 50%;
      height: 36px;
      width: 36px;
      line-height: 18px;
      padding: 7px 8px;
      /* padding-left: 12px; */
  }
  .mob_brd:after {
    content: " ";
    height: 0;
    border-radius: 2px;
    display: block;
    border: 1px #cdcdcd;
    border-style: solid solid none;
  }
  .bui_u_margin8{
    margin-right: 8px;
  }
  .menu_item{
    margin: auto 0;
  }


@media (max-width: 767px){
  
    .hamburger{
        display:fixed;
        padding-top: 10px;
        margin-left: 10px;
        z-index: 6;
    }


   
    .navigation {
        display:  none;
        background-color:white ;
        height: 100vh;
        width: 50vw;
        margin-top: 50px;
        position: fixed;
        

    }
    .navigation_ineline {
      display: flex !important;
      background-color: white;
      /* height: 100vh; */
      /* width: 77vw; */
      /* margin-top: 38px; */
      position: absolute;
      /* margin-left: -350px; */
      /* position: fixed; */
      top: 72px;
      right: 8px;
      flex-direction: column;
      border-radius: 20px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      padding: 0 10px;
    }

    .mobile-layoutwrapper .navigation_ineline{
      min-height: 400px;
      overflow-y: scroll;
    }

    .navigation_ineline:before{
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      margin-left: 0;
      bottom: 0;
      top: 0;
      right: -7px;
      box-sizing: border-box;
      border-color: transparent transparent #fff #fff;
      border-style: solid;
      border-width: 7px;
      transform-origin: 0 0;
      transform: rotate(130deg);
      box-shadow: -3px 3px 2px 0px rgb(0 0 0 / 15%);
    }

    .navigation_ineline > div > div > span{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 15px;
      font-weight: 400;
      margin: 12px 0;
      cursor: pointer;
      color: var(--font_color);
    }

    .navigation_ineline > div > div > span > div{
      padding: 5px;
      background: var(--secondary_color);
      border-radius: 50px;
      margin-right: 12px;
    }
    .navigation_ineline > div > div > span > div img{
      padding: 2px;
      filter: brightness(4.5);
    }

    .navigation_ineline > div > div{
      padding: 10px;
    }

    .navigation_ineline > div > div:last-child {
      padding-top: 0;
      border-top: 1px solid var(--secondary_color);
    }
    
    .navigation_ineline > div > div:last-child > div{
      font-size: 18px;
      font-weight: 600;
      padding-top: 20px;
      padding-bottom: 10px;
    }

    .navigation_ineline > div > div:last-child > a span{
      cursor: pointer;
      color: var(--font_color);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 12px 0;
    }

    .navigation_ineline > div > div:last-child > a span div{
      padding: 5px;
      background: var(--secondary_color);
      border-radius: 50px;
      margin-right: 12px;
    }
    
    .navigation_ineline > div > div:last-child > a span div img{
      filter: brightness(10);
      padding: 2px;
    }

    .navigation_ineline > div > div > span:hover{
      font-weight: 700;
      color: var(--primary_color);
    }


}