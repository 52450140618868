.footer_main {
    background: var(--primary_color);
    background: #464646;
    color: #fff;
    font-family: var(--font_family);
    font-size: 14px;
    padding: 40px 0;
    padding-bottom: 0px;
}

.footer_logo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    width: 20%;
    text-align: center;
}

.footer_logo input, .footer_logo img {
    height: 100px;
    width: 100%;
    object-fit: scale-down;
    margin-bottom: 20px;
}

.footer_links a {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.22;
    letter-spacing: -0.45px;
    text-align: left;
    color: #ffff;
    width: 33%;
    padding: 0 10px;
}

.footer_links {
    display: flex;
    flex-wrap: wrap;
    width: 45%;
}

.footer_send_email {
    width: 23%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.social_media input, .social_media img {
    height: 35px;
    width: 35px;
    object-fit: contain;
    margin: 0 8px;
}

.social_media {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 10px 0;
    justify-content: center;
}

.payment_card {
    display: flex;
    align-items: center;
    width: 100%;
    opacity: .7;
    justify-content: center;
}

.payment_card input, .payment_card img {
    height: 35px;
    width: 35px;
    object-fit: contain;
    margin: 0 8px;
}

.email_block label{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    margin-bottom: 5px;
    display: block;
}

.email_block input{
    padding: 6px 15px;
    margin-top: 8px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    width: 65%;
    display: inline-block;
    float: left;
    color: var(--font_color);
}

.email_block button{
    padding: 8px 5px;
    margin-top: 8px;
    margin-right: 0;
    background: var(--secondary_color);
    color: #fff;
    font-size: 14px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 6px;
    display: inline-block;
    float: left;
    width: 30%;
}

.email_block{
    margin: 0 10px;
    width: 100%;
    margin-bottom: 20px;
}

footer .menu_footer_new li {
    display: inline-block;
    float: left;
    /* margin-right: 10px; */
}

.wp_wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid;
}

.menu_footer_new li a {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid;
}

.menu_footer_new li:last-child a {
    border-right: 0;
    margin: 0;
    padding: 0;
}

.ax_head_menu img {
    display: inline;
    margin-right: 10px;
    vertical-align: middle;
    width: 23px;
}
.ax_head_menu svg{
    display: inline;
    margin-right: 5px;
}

.wpb_wrap_contact {
    display: flex;
}

.ax_head_menu {
    margin-right: 15px;
}

.wpb_wrap_contact a:last-child .ax_head_menu {
    margin-right: 0;
}
.travel_copyright {
    background-color: #878787;
    color: #fff;
    text-align: center;
    padding: 24px 15px;
    font-size: 12px;
}


@media screen and (min-width: 320px) and (max-width: 767px){
    .footer_main .footer_nwContainer {
        flex-direction: column;
        padding: 0 15px;
    }
    
    .footer_main .footer_logo {
        width: 50%;
    }
    
    .footer_main .footer_links {
        width: 100%;
    }
    
    .footer_main .footer_links a {
        width: 100%;
        padding: 0;
    }
    
    .footer_main .social_media {
        width: 100%;
    }
    
    
    .footer_send_email {
        width: 100%;
    }
    
    .footer_main {
        clear: both;
        padding: 15px 0;
        padding-bottom: 0;
    }
    
    .wp_wrapper {
        margin-top: 5px;
        flex-direction: column;
    }
    
    .wpb_wrap_contact {
        flex-direction: column;
    }
    
    .wpb_wrap_contact a {
        margin-bottom: 5px;
    }
    .footer_logo input, .footer_logo img{
        height: inherit;
    }
}