.footer_main {
    background: var(--primary_color);
    color: #fff;
    font-family: var(--font_family);
    font-size: 14px;
    /* padding: 40px 0; */
}

.footer_logo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    width: 20%;
    text-align: center;
}

.footer_logo input, .footer_logo img {
    height: 100px;
    width: 100%;
    object-fit: scale-down;
    margin-bottom: 20px;
}

.footer_links a {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.22;
    letter-spacing: -0.45px;
    text-align: left;
    color: #ffff;
    width: 33%;
    padding: 0 10px;
}

.footer_links {}

.footer_links {
    display: flex;
    flex-wrap: wrap;
    width: 45%;
}

.footer_send_email {
    width: 23%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.social_media input, .social_media img {
    height: 35px;
    width: 35px;
    object-fit: contain;
    margin: 0 8px;
}

.social_media {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 10px 0;
    justify-content: center;
}

.payment_card {
    display: flex;
    align-items: center;
    width: 100%;
    opacity: .7;
    justify-content: center;
}

.payment_card input, .payment_card img {
    height: 35px;
    width: 35px;
    object-fit: contain;
    margin: 0 8px;
}

.email_block label{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    margin-bottom: 5px;
    display: block;
}

.email_block input{
    padding: 6px 15px;
    margin-top: 8px;
    font-size: 14px;
    border: none;
    border-radius: 12px 0 0 12px;
    width: 65%;
    display: inline-block;
    float: left;
    color: var(--font_color);
}

.email_block button{
    padding: 8px 5px;
    margin-top: 8px;
    margin-right: 0px;
    background: var(--secondary_color);
    color: #ffff;
    font-size: 14px;
    border: none;
    cursor: pointer;
    border-radius: 0 12px 12px 0px;
    display: inline-block;
    float: left;
    width: 30%;
}

.email_block{
    margin: 0 10px;
    width: 100%;
    margin-bottom: 20px;
}

.footer_new {
    background-color: #02374c;
    color: #ffffff;
    width: 100%;
  }
  .child_divEmpty{
    /* height: 35px; */
    background: 
    #02374c;
  }
  .child_divFlx{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .footer_Mainsection {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 10px;
    flex-wrap: wrap;
  }
  .footer_Mainsection a{
    border-right: 1px solid #fff;
    padding-right: 10px;
  }
  .footer_Mainsection a:last-child{
    border-right: 1px solid transparent;
  }
  .footer_Socialsection{
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .section_mainChild{
    background: var(--primary_color);
  }

  .copyright {
    font-size: 12px;
    text-align: center;
    margin-top: 30px;
    padding-bottom: 15px;
  }

  @media screen and  (max-width: 767px) {
    .footer_Mainsection{
      justify-content: center;
    }
    .footer_Mainsection a{
      padding-right: 15px;
    }
    
  }