.new_header{
    position: fixed;
    box-shadow: 0px 4px 5px 0 rgb(0 0 0 / 10%);
    background: #fff;
    width: 100%;
    height: 75px;
    top: 0px;
    padding: 0 10px;
}
.main_logo_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 999;
    background: #fff;
    width: 100%;
    display: flex;
    padding: 5px 10px;
    left: 0;
    right: 0;
    top: 0;
    box-shadow: 0px 4px 5px 0 rgb(0 0 0 / 10%);
}

.header_sections input[type="image"],.header_sections img {
    height: 23px;
    width: 23px;
    object-fit: scale-down;
    filter: invert(1);
}


.logo_header{
    display: flex;
    align-items: center;
}
.logo_header img{
    height: 60px;
    max-width: 150px;
    object-fit: scale-down;
}
.login_btn button {
    font-family: var(--font_family);
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    color: #ffffff;
}

.login_btn input, .login_btn img {
    height: 17px;
    width: 17px;
    margin-right: 8px;
    object-fit: scale-down;
}
.logosub_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 25px;
}

.support_btn input, .support_btn img{
    height: 23px;
    width: 23px;
    object-fit: scale-down;
    margin-right: 8px;
}

.header_all_section{
    padding: 14px 25px 8px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-evenly;
    background-color: var(--primary_color);
    margin: 4px 0;
    border-radius: 10px 10px 0px 0px;
    height: 62px;
}

.header_all_section .header_sections:last-child{
    margin-right: 0;
}

.header_section{
    padding-top: 0px;
    position: fixed;
    z-index: 999;
    background: #fff;
    width: 100%;
    height: 70px;
    box-shadow: 0px 4px 5px 0 rgb(0 0 0 / 10%);
    left: 0;
    right: 0;
    top: 0;
}
.logosub_header img{
    /* margin: 0 5px; */
    height: 28px;
    width: 28px;
}
.profile_details_hide{
    display: none;
    }

    .show {
        position: absolute;
        width: 200px;
        object-fit: contain;
        box-shadow: 0 0 5px 2px rgb(0 0 0 / 15%);
        background-color: #ffffff;
        right: -5px;
        top: 50px;
        padding: 10px;
        user-select: none;
        text-align: left;
        z-index: 99;
    }

    .afterlogin{
        display: flex;
        align-items: center;
        position: relative;
    }

    .afterlogin > span:first-child{
        background: var(--secondary_color);
        height: 35px;
        width: 35px;
        border-radius: 50%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        margin: 0 5px;
    }

    .afterlogin > span img{
        height: 15px;
        width: 15px;
        object-fit: scale-down;
        cursor: pointer;
    }

    .show >span{
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: normal;
        margin: 12px 0;
        cursor: pointer;
        color: var(--font_color);
    }

    .show>span:hover{
        font-weight: bold;
        color: var(--primary_color);
    }

    .show >span img{
        height: 25px;
        width: 25px;
        /* object-fit: scale-down; */
        margin-right: 12px;
    }