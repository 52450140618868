.modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  .modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    z-index:999;
    width: 400px;
  }
  .assist_book_cont{
    padding: 0 15px;
  }

  .agnc_head{
    font-size: 16px;
    font-family: var(--font_family);
    font-weight: 600;
    color: var(--primary_color);
    margin-bottom: 5px;
    text-transform: capitalize;
  }
  .inp_flex{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .select_bx{
    height: 40px;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 20px;
    text-transform: capitalize;
  }
  .select_bx_new{
    height: 40px;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 10px;
    text-transform: capitalize;
  }
  .subagncy_label{
    font-size: 14px;
    color: var(--primary_color);
    font-family: var(--font_family);
    margin: 15px 5px;
  }
  .ok_btn{
    border: 1px solid var(--buttons_color);
    display: block;
    margin: 10px auto;
    background: var(--buttons_color);
    color: #fff;
    padding: 6px 25px;
    border-radius: 20px;
    text-transform: uppercase !important;
    position: relative;
  }
  .ok_btn_disable{
    border: 1px solid gray !important;
    display: block;
    margin: 10px auto;
    background: gray !important;
    color: #fff;
    padding: 6px 25px;
    border-radius: 20px;
    text-transform: uppercase !important;
    position: relative;
    pointer-events: none !important;

  }

  .dropdown {
    /* border: 0.5px solid grey; */
    /* padding-top: 10px; */
    /* margin-top: -12px; */
    box-shadow: 0px 0px 2px 0px black;
    /* padding-left: 20px; */
    border-radius: 5px;
    max-height: 120px;
    width: 100%;
    overflow-y: scroll;
  }
  .dropdown::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
  }
  .dropdown::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
  }
    .dropdown::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px;
  }
    .dropdown::-webkit-scrollbar-thumb:hover {
    background: #555; 
    border-radius: 10px;
  }

  .dropdown .users {
    /* padding-left: 20px; */
    margin-bottom: 10px;
    margin-top: 5px;
    cursor: pointer;
    font-family: var(--font_family);
    font-size: 16px;
    padding: 5px 20px;
  }

  .dropdown .users:hover {
    background-color: var(--secondary_color);
    color: white;
  }

  .crossbutton {
    width: 20px;
    height: 20px;
    float: right;
    cursor: pointer;
    border: 1px solid var(--font_color);
    border-radius: 50%;
    background-color: var(--font_color);
    color: white;
  }

  .login_loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid var(--font_color);
    width: 33px;
    height: 33px;
    position: absolute;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin: auto;
    left: 50%;
    top: 50%;
    background: var(--secondary_color);
    transform: translate(-50%, -50%);
    /* top: -76px;
    left: 45%;
    right: 50%; */
  }