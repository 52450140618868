.new_header_wrap {
  width: 100%;
  background: #fff;
  height: 90px;
  position: absolute;
  z-index: 999;
  box-shadow: 0px 4px 5px 0 rgb(0 0 0 / 10%);
}

.header_sticky {
  width: 100%;
  background: #fff;
  /* background: transparent; */
  height: 90px;
  position: fixed;
  top: 0;
  z-index: 100;
  box-shadow: 0px 4px 5px 0 rgb(0 0 0 / 10%);
  transition: background-color 0.5s ease-in;
}

.header_all_section {
  padding: 14px 25px 8px 52px;
  display: flex;
  margin: 4px 0;
  border-radius: 10px 10px 0px 0px;
  /* height: 86px; */
}
.header_all_section .header_sections:last-child {
  margin-right: 0;
}
.header_sections {
  display: inline-flex;
  margin-right: 33px;
  /* font-size: 9px; */
  /* color: #fff; */
  padding-top: 4px;
}
.header_Container {
  height: 82px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.header_allService {
  width: 62%;
}
.header_sections a {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: var(--font_family);
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
  padding-bottom: 5px;
}
.header_sections img,
.header_sections input[type="image"] {
  width: 55px;
  height: 55px;
  display: none;
  /* -o-object-fit: scale-down;
    object-fit: scale-down;
    filter: invert(1); */
}
.active_hometab a {
  color: var(--primary_color);
  border-bottom: 3px solid var(--primary_color);
}

.header_logo {
  height: 80px;
  width: auto;
  cursor: pointer;
  margin-top: 5px;
}

div.header_section {
  width: 1270px;
  margin: 0 auto;
}

.header_section {
  padding-top: 0px;
  position: absolute;
  z-index: 999;
  background: transparent;
  width: 100% !important;
  height: 70px;
  left: 0;
  right: 0;
  top: 0;
}

/* new design */

.login_container {
  display: flex;
  width: 30%;
  justify-content: flex-end;
}
.contact_title {
  background-color: #ffc400;
  border-radius: 5px 5px 0 0;
  /* margin-right: 10px; */
  width: 120px;
  padding-top: 5px;
  padding-bottom: 0;
  position: relative;
  top: 4px;
  text-align: center;
  font-size: 12px;
}
.contact_number img {
  width: 20px;
  height: 20px;
  display: inline;
  margin-right: 4px;
}
.login_contact_dtail {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
}
.login_container ul li {
  list-style-type: none;
}
.login_container ul li a {
  padding: 2px 10px;
  padding-left: 0;
  display: block;
  color: #333;
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
}
.navbar_right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  margin-right: 20px;
}
.navbar_right .dropdown .dropdown_span {
  color: #303030;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 1rem;
  display: block;
  display: flex;
  align-items: center;
  /* border-right: 1px solid #696969; */
  color: #696969;
  font-family: var(--font_family);
  font-size: 12px;
  padding: 0 1em 0 5px;
}
.navbar_right .dropdown .dropdown_span svg {
  width: 25px;
  height: 25px;
  float: none;
  margin: 0 5px 0 0;
  display: inline;
  margin-top: -4px;
}
.navbar_right .dropdown:last-child a {
  border-right: 0;
}
.flag {
  width: 25px;
  height: 25px;
  float: none;
  margin: 0 5px 0 0;
  display: inline;
  margin-top: -4px;
}
.drop_arrow {
  display: inline;
  margin-left: 18px;
  width: 15px;
  margin-top: -3px;
}
.profile_icon {
  display: inline;
  margin-right: 5px;
  width: 25px;
  padding: 1px;
  border-radius: 4px;
  margin-top: -4px;
}
.divider_line {
  position: relative;
  /* bottom: 2px; */
  margin: 0 4px;
  font-size: 18px !important;
  left: 0;
  bottom: 0px;
}

.dropdown_menu_wrap {
  position: absolute;
  left: auto;
  min-width: 140px;
  top: 25px;
  right: 0;
  z-index: 3;
  width: 230px;
  top: 30px;
  padding: 20px 20px;
  font-family: var(--font_family);
  font-size: 14px;
  background: #fff;
  box-shadow: 0 1.5px 3px 0 rgb(0 0 0 / 16%);
  border-radius: 10px;
}
.nation_flag {
  width: 20px;
  height: 20px;
  display: inline;
  margin-right: 5px;
  margin-top: -2px;
}
.nation_title_wrap {
  padding: 5px 20px 5px 5px;
  background-color: #fff;
  border: 1px solid #575656;
  border-radius: 5px;
  display: flex;
  align-items: center;
  position: relative;
}
.nation_title_wrap:before {
  content: "";
  height: 10px;
  width: 15px;
  background-image: url("/images/down_arrow.jpg");
  background-repeat: no-repeat;
  position: absolute;
  top: 10px;
  left: auto;
  background-color: #fff;
  right: 10px;
}
.dropdown {
  position: relative;
}
.language_label {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
  color: var(--font_color);
}
.nation_label {
  font-weight: 500;
  font-size: 12px;
  color: var(--font_color);
}
.dropdown_menu_wrap {
  outline: 0;
}
.language_drop {
  position: relative;
  margin: 10px 0;
}
.language_drop:first-child {
  margin-top: 0;
}
.language_drop:last-child {
  margin-bottom: 0;
}

.country_drop_container {
  z-index: 9999;
  position: absolute;
  height: 150px;
  width: 100%;
  top: 65px;
  display: block;
  background-color: #fff;
  /* left: -1px; */
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border: 1px solid #575656;
  border-radius: 5px;
}

.country_scroll_box {
  overflow-y: scroll;
  height: 100%;
  max-width: 100%;
}

.country_scroll_box::-webkit-scrollbar {
  width: 6px;
}

.country_scroll_box::-webkit-scrollbar-track {
  background: #bbb;
}

.country_scroll_box::-webkit-scrollbar-thumb {
  background: #3479c5;
}

.country_scroll_box::-webkit-scrollbar-thumb:hover {
  background: #166ac7;
}

.country_dtail_wrap {
  overflow: hidden;
  width: auto;
  height: auto;
  /* margin-right: 15px; */
}

.country_name {
  padding: 8px 5px;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

li.country_name:hover {
  background-color: var(--primary_color);
  color: #fff;
}

.drop_my_account {
  position: absolute;
  left: auto;
  min-width: 140px;
  right: 0;
  z-index: 3;
  top: 30px;
  padding: 20px;
  font-family: var(--font_family);
  font-size: 14px;
  background: #fff;
  box-shadow: 0 1.5px 3px 0 rgb(0 0 0 / 16%);
  border-radius: 10px;
}

.account_container .drop_my_account li {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  justify-content: center;
}

.drop_my_account li a {
  text-transform: capitalize !important;
}

.login a span {
  background: #dc0d15;
  color: #ffffff;
  padding: 5px 18px;
  border-radius: 5px;
}

.login a {
  padding: 0 !important;
}

.signup a {
  padding: 0 !important;
}

.signup a span {
  border: 1px solid var(--secondary_color);
  padding: 5px 10px;
  border-radius: 5px;
  color: #000;
}
.login .user_icon {
  display: inline;
  margin-right: 5px;
  width: 20px;
  padding: 1px;
  border-radius: 4px;
  margin-top: -2px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7437%)
    hue-rotate(32deg) brightness(116%) contrast(106%);
}
.signup .user_icon {
  display: inline;
  margin-right: 5px;
  width: 20px;
  padding: 1px;
  border-radius: 4px;
  margin-top: -2px;
}

/* css from old header */

.afterlogin {
  display: flex;
  align-items: center;
  position: relative;
}
.logindrop {
  position: absolute;
  width: 250px;
  object-fit: contain;
  box-shadow: 0 0 5px 2px rgb(0 0 0 / 15%);
  background-color: #ffffff;
  right: 1px;
  top: 42px;
  padding: 10px;
  user-select: none;
  text-align: left;
  z-index: 99;
}
.logindrop .ax-head-menu {
  padding: 10px;
  cursor: pointer;
}

.afterlogin > span:first-child {
  background: var(--secondary_color);
  height: 25px;
  width: 25px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
}

.afterlogin > span img {
  height: 15px;
  width: 15px;
  object-fit: scale-down;
  cursor: pointer;
}

.show > span {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: normal;
  margin: 12px 0;
  cursor: pointer;
  color: var(--font_color);
}
.show {
  position: absolute;
  width: 180px;
  object-fit: contain;
  box-shadow: 0 0 5px 2px rgb(0 0 0 / 15%);
  background-color: #ffffff;
  right: -10px;
  top: 50px;
  /* padding: 10px; */
  user-select: none;
  text-align: left;
  z-index: 99;
  border-radius: 10px;
}

/* .show:before {
  z-index: 10;
     content: "";
     position: absolute;
     width: 0;
     height: 0;
     margin-left: 0;
     bottom: 0;
     top: 0;
     right: -7px;box-sizing: border-box;
     border: 7px solid black;
     border-color: transparent transparent #fff #fff;
     transform-origin: 0 0;
     transform: rotate(130deg);
    }
    .show:after {
        z-index: -10;
     content: "";
     position: absolute;
     width: 0;
     height: 0;
     margin-left: 0;
     bottom: 0;
     top: 0;
     right: -7px;box-sizing: border-box;
     border: 7px solid #fff;
     border-color: transparent transparent #fff #fff;
     transform-origin: 0 0;
     transform: rotate(130deg);
     box-shadow: -2px 2px 4px 0px rgb(0 0 0 / 25%);
    } */

.show > span:hover {
  font-weight: bold;
  color: var(--primary_color);
}

.show > span img {
  height: 25px;
  width: 25px;
  /* object-fit: scale-down; */
  margin-right: 12px;
}

.profile_details_hide {
  display: none;
}

/* .profile_frst {
            padding: 10px;
            } */
.profile_frst > span {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  padding: 6px 12px;
  cursor: pointer;
  color: var(--font_color);
}
.profile_frst > span:hover {
  font-weight: bold;
  /* color: var(--primary_color); */
  background-color: #e7e7e7;
}
.profile_frst > span img {
  height: 20px;
  width: 20px;
  /* margin-right: 12px; */
}
/* .profile_frst>span:hover > .menu_icon {background: var(--primary_color);} */

.profile_frst > span:hover > .menu_icon img {
  filter: invert(99%) sepia(12%) saturate(0%) hue-rotate(52deg) brightness(112%)
    contrast(100%);
}
.profile_frst > a > span {
  display: flex;
  align-items: center;
  word-break: break-all;
  font-size: 13px;
  font-weight: 400;
  padding: 5px 12px;
  cursor: pointer;
  color: var(--font_color);
}
.profile_frst > a > span:hover {
  font-weight: bold;
  color: var(--primary_color);
  background-color: #e7e7e7;
}
.profile_frst > a > span img {
  height: 20px;
  width: 20px;
  /* margin-right: 12px; */
}
/* .profile_frst>a>span:hover > .menu_icon {background: var(--primary_color);}
    
            .profile_frst>a>span:hover > .menu_icon img {
                filter: invert(99%) sepia(12%) saturate(0%) hue-rotate(52deg) brightness(112%) contrast(100%);
            }      */
.menu_icon {
  padding: 5px;
  /* background: #e7e7e7; */
  border-radius: 50px;
  margin-right: 12px;
}

.download_btn_cont {
  margin-right: 20px;
}
.download_button {
  font-size: 14px;
  padding: 7px 15px;
  border-radius: 4px;
  background-color: var(--buttons_color);
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.download_button img {
  width: 12px;
  margin-right: 5px;
}
.dropZ_arrow {
  font-size: 20px !important;
  display: inline-block;
  margin-top: 0px;
  line-height: 1;
  margin-left: 0 !important;
}
.znth_main {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  /* cursor: pointer; */
}
.znth_main span {
  color: var(--secondary_color);
}
.znth_support {
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}
.znth_support span {
  font-size: 18px;
  font-weight: bold;
  color: var(--secondary_color);
  margin-left: 5px !important;
}
.login_button {
  font-size: 14px;
  padding: 7px 15px;
  font-weight: bold;
  border-radius: 4px;
  background-color: var(--buttons_color);
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.suport_cont {
  position: relative;
  display: flex;
  align-items: center;
}

.supportdropNew {
  position: absolute;
  width: 290px;
  border-radius: 4px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e6e7e8;
  background-color: #ffffff;
  right: 0;
  top: 30px;
  padding: 20px 15px;
  padding-bottom: 0px;
  text-align: right;
  z-index: 9;
}
.header_menuZth {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 20px;
}
.header_menuZth img {
  width: 35px;
  background: #e4e4e4;
  border-radius: 50px;
  padding: 7px;
}
.language_dropMain {
  position: absolute;
  width: 290px;
  border-radius: 4px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e6e7e8;
  background-color: #ffffff;
  right: 0;
  top: 40px;
  padding: 20px 15px;
  text-align: right;
}
.language_mnCont {
  display: flex;
  justify-content: space-between;
}
.language_wrap {
  text-align: left;
}
.lang_title {
  font-size: 13px;
  font-weight: normal;
  color: var(--font_color);
  margin-bottom: 7px;
}
.lang_label {
  font-size: 15px;
  font-weight: bold;
  color: var(--font_color);
}
.apply_btn {
  text-align: center;
  margin-top: 15px;
}
.apply_btn button {
  padding: 11px 32px;
  border-radius: 10px;
  background-color: #d4d4d4;
  color: #fff;
}
.afterloginNew {
  font-size: 14px;
  font-weight: bold;
  padding: 7px 15px;
  border-radius: 4px;
  background-color: var(--buttons_color);
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  position: relative;
  height: 35px;
  cursor: pointer;
}
.afterloginNew .afterloginArrow {
  font-size: 20px !important;
  display: inline-block;
  margin-top: 0px;
  line-height: 1;
  cursor: pointer;
}
.dropdown_span img {
  margin-right: 4px;
  width: 25px;
}
