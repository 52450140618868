input.header_logo {
    height: 60px;
    max-width: 150px;
    object-fit: scale-down;
}

.header_sections {
    display: inline-flex;
    margin-right: 20px;
    font-size: 9px;
    color: #fff;
    padding-top: 4px;
}

.header_sections a {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #fff;
    cursor: pointer;
}

.header_sections input[type="image"],.header_sections img {
    width: 36px;
    height: 35px;
    object-fit: scale-down;
    filter: invert(1);
}
.logindrop {
    position: absolute;
    width: 260px;
    object-fit: contain;
    box-shadow: 0 0 5px 2px rgb(0 0 0 / 15%);
    background-color: #ffffff;
    right: 1px;
    top: 72px;
    padding: 10px;
    user-select: none;
    text-align: left;
    z-index: 99;
  }
  .logindrop .ax-head-menu {
    padding: 10px;
    cursor: pointer;
  }


.login_btn {
    color: #ffffff;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    height: 41px;
    border: 1px solid var(--secondary_color);
    background-color: var(--secondary_color);
    display: flex;
    align-items: center;
    padding: 3px 8px;
    white-space: nowrap;
}

.login_btn button {
    font-family: var(--font_family);
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    color: #ffffff;
}

.login_btn input, .login_btn img {
    height: 17px;
    width: 17px;
    margin-right: 8px;
    object-fit: scale-down;
}

.support_btn {
    font-family: var(--font_family);
    font-size: 16px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    text-align: right;
    color: var(--font_color);
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    margin-left: 25px;
}

.support_btn input, .support_btn img{
    height: 23px;
    width: 23px;
    object-fit: scale-down;
    margin-right: 8px;
}

.header_all_section{
    padding: 14px 25px 8px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-evenly;
    background-color: var(--primary_color);
    margin: 4px 0;
    border-radius: 10px 10px 0px 0px;
    height: 86px;
}

.header_all_section .header_sections:last-child{
    margin-right: 0;
}


.header-new{
    background: #fff;
    width: 100%;
    position: fixed;
    z-index: 999;
    box-shadow: 0px 4px 5px 0 rgb(0 0 0 / 10%);
}
div.header_section{
    width:1270px;
    margin: 0 auto;
}

.header_section{
    padding-top: 0px;
    position: fixed;
    z-index: 999;
    background: #fff;
    width: 100%!important;
    height: 70px;
   
    left: 0;
    right: 0;
    top: 0;
}
/* 
.header_section>div{
max-width: 1200px;
margin: 0 auto;
} */
/* 
img.header_logo {
    height: 57px;
}
.header_sections img {
    width: 22px;
    height: auto;
    margin-bottom: 6px;
}
.login_btn img {
    width: 12%;
    margin-right: 5px;
}
.support_btn img {
    width: 23%;
    margin-right: 5px;
} */

.profile_details_hide{
    display: none;
    }


    .show {
        position: absolute;
        width: 250px;
        object-fit: contain;
        box-shadow: 0 0 5px 2px rgb(0 0 0 / 15%);
        background-color: #ffffff;
        right: -10px;
        top: 50px;
        /* padding: 10px; */
        user-select: none;
        text-align: left;
        z-index: 99;
    }

 .show:before {
  z-index: 10;
     content: "";
     position: absolute;
     width: 0;
     height: 0;
     margin-left: 0;
     bottom: 0;
     top: 0;
     right: -7px;box-sizing: border-box;
     border: 7px solid black;
     border-color: transparent transparent #fff #fff;
     transform-origin: 0 0;
     transform: rotate(130deg);
    }
    .show:after {
        z-index: -10;
     content: "";
     position: absolute;
     width: 0;
     height: 0;
     margin-left: 0;
     bottom: 0;
     top: 0;
     right: -7px;box-sizing: border-box;
     border: 7px solid #fff;
     border-color: transparent transparent #fff #fff;
     transform-origin: 0 0;
     transform: rotate(130deg);
     box-shadow: -2px 2px 4px 0px rgb(0 0 0 / 25%);
    }

    .profile_frst {
        padding: 10px;
        }
        .profile_frst > span {
            display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 400;
        margin: 12px 0;
        cursor: pointer;
        color: var(--font_color);
        }
        .profile_frst > span:hover{
            font-weight: bold;
            color: var(--primary_color);
        }
        .profile_frst > span img{
        height: 20px;
        width: 20px;
        /* margin-right: 12px; */
        }
        .profile_frst>span:hover > .menu_icon {background: var(--primary_color);}

        .profile_frst>span:hover > .menu_icon img {
            filter: invert(99%) sepia(12%) saturate(0%) hue-rotate(52deg) brightness(112%) contrast(100%);
        }
        .profile_frst > a > span {
            display: flex;
        align-items: center;
        word-break: break-all;
        font-size: 13px;
        font-weight: 400;
        margin: 12px 0;
        cursor: pointer;
        color: var(--font_color);
        }
        .profile_frst > a > span:hover{
            font-weight: bold;
            color: var(--primary_color);
        }
        .profile_frst > a > span img{
        height: 20px;
        width: 20px;
        /* margin-right: 12px; */
        }
        .profile_frst>a>span:hover > .menu_icon {background: var(--primary_color);}

        .profile_frst>a>span:hover > .menu_icon img {
            filter: invert(99%) sepia(12%) saturate(0%) hue-rotate(52deg) brightness(112%) contrast(100%);
        }
        .support_scnd {
            padding-top: 0;
            border-top: 1px solid #e0e0e0;
        }
        .menu_icon{
            padding: 5px;
            background: #e7e7e7;
            border-radius: 50px;
            margin-right: 12px;
        }
        .profile_frst .support_heading {
            font-size: 18px;
            font-weight: 600;
            padding-top: 20px;
            padding-bottom: 10px;
        }

    .afterlogin{
        display: flex;
        align-items: center;
        position: relative;
    }
    .logindrop {
        position: absolute;
        width: 250px;
        object-fit: contain;
        box-shadow: 0 0 5px 2px rgb(0 0 0 / 15%);
        background-color: #ffffff;
        right: 1px;
        top: 42px;
        padding: 10px;
        user-select: none;
        text-align: left;
        z-index: 99;
      }
      .logindrop .ax-head-menu {
        padding: 10px;
        cursor: pointer;
      }

    .afterlogin > span:first-child{
        background: var(--secondary_color);
        height: 35px;
        width: 35px;
        border-radius: 50%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        margin-right: 10px;
    }

    .afterlogin > span img{
        height: 15px;
        width: 15px;
        object-fit: scale-down;
        cursor: pointer;
    }

    .show >span{
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: normal;
        margin: 12px 0;
        cursor: pointer;
        color: var(--font_color);
    }

    .show>span:hover{
        font-weight: bold;
        color: var(--primary_color);
    }

    .show >span img{
        height: 25px;
        width: 25px;
        /* object-fit: scale-down; */
        margin-right: 12px;
    }
    
    .cmpny_agnt_nme{
        width: 25%;
    }
    .cmpny_nme_wp{
        display: flex;
    }
    .cmpny_nme_wp .cmpny_title{
        font-family: var(--font_family);
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.35px;
        color: var(--font_color);
    }
    .cmpny_name{
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.35px;
        color: var(--primary_color);
        width: 50%;
        overflow: hidden;
        position: relative;
        display: inline-block;
        margin: 0 5px 0 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .cmpny_prce_limit{
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.35px;
        color: var(--font_color);
        margin-left: 4px;
    }
    .assisted_btn{
        background: var(--buttons_color);
        color: #fff;
        padding: 6px 15px;
        border-radius: 10px;
    }