

body{
    /* font-family: var(--font_family); */
    font-family: var(--font_family);
    color: var(--font_color) !important;
    font-size: 16px;
}

input::placeholder, input::-webkit-input-placeholder { 
  color: var(--font_color);
}
.react-datepicker-popper {
  z-index: 99;
}
.MuiTypography-body1
{
  font-family: unset!important;
}
input[type="radio"] {
  cursor: pointer;
}
input[type='radio']:focus {
  box-shadow: none;
}
/* input[type="text"]::placeholder, input[type="text"]::-webkit-input-placeholder {
  color: var(--font_color);
}
textarea::placeholder, textarea::-webkit-input-placeholder { 
  color: var(--font_color);
}
input:-ms-input-placeholder, input::-ms-input-placeholder{
  color: var(--font_color);
}
input:-moz-placeholder, input::-moz-placeholder { 
 color: var(--font_color);
 opacity:  1;
} */

input, select {
   font-size: 100%; 
}

@media (min-width: 1280px) {
  .xl\:max-w-5xl {
    max-width: 100rem !important;
  }

  /* background-color: violet !important; */
}

button {
  outline: none;
}
button, input[type="button"] {
  text-transform: capitalize !important;
}

/* login with google */
.btn-google-icon {
  height: 50px;
  width: -moz-max-content;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-family: var(--font_family);
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: .38px;
  text-align: left;
  color: #4a4a4a;
  border: 1px solid var(--primary_color); 
  margin: auto !important;
}

.btn-google-icon img {
  height: 30px;
  width: 30px;
  /* margin-right: 10px; */
}

/* --------------- */

/* login with facebook */

div .kep-login-facebook {
  padding: 0;
  height: 52px;
  width: 100%;
  background: #4267b2;
  font-size: 15px;
  font-family: var(--font_family);
  font-weight: normal;

  font-stretch: normal;

  font-style: normal;

  line-height: normal;

  letter-spacing: 0.38px;

  text-align: left;
  text-transform: capitalize;

  color: #fff;
  border-radius: 20px !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

div .kep-login-facebook img {
  height: 34px;
  width: 34px;
  margin-right: 10px;
}

/* ---------------------- */

.facebook_google_login {
  display: block;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  /* margin: 0.5rem 0; */
}

@media only screen and (max-width: 767px) {
  .facebook_google_login {
    flex-direction: column;
    justify-content: center;
  }

  .facebook_google_login button {
    margin: 0.5rem 0;
  }
  .contactus_contact_support {
    width: 100%;
}
.landing_tabcontainer{
  width: 100% !important;
}
}

/* ---------------------- */
/* testimonial  */
.testimonial_carousel {
  width: 100%;
  padding: 50px 0;
  background: #036581;
  display: flex;
}

.testimonial_left {
  width: 22%;
  margin-right: 20px;
}

.testimonial_right {
  width: 60%;
}

.testimonial_left img {
  height: 80%;
  width: 80%;
  object-fit: scale-down;
}

.testimonial_text {
  font-family: var(--font_family);
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: center;
  color: #ffff;
  margin-bottom: 25px;
}

.testimonial_name {
  font-family: var(--font_family);
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: center;
  color: #ffff;
  margin-bottom: 5px;
}

.testimonial_header {
  font-family: var(--font_family);
  font-size: 35px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  text-align: center;
  color: #ffff;
  margin-bottom: 25px;
}

.testimonial_star img {
  height: 25px;
  margin: auto;
}

.main-div-itemscarousle {
  margin: 30px 0;
}

/* ------------ */

div .react-multiple-carousel__arrow {
  display: none;
}

div .carousel-container {
  margin: 30px 0;
}

/* ------------ */


/* offline-css */
.shared-offileheader .carousel-container {
  margin: 10px 0px 0px;
  height: 418px;
}

.holidaycarousel img {
  height: 418px;
  object-fit: cover;
}

/* .g-signin2 {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
} */

#google-sign-in-button {
  padding: 100px;
}



/* CSS By Sarika */
.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #FFF;
}

.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}

.loginBtn:focus {
  outline: none;
}

.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

.navbartab ul li .active-tab {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: left;
  color: var(--primary_color);
}

/* Google */
.loginBtn--google {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background: #DD4B39;
}

.loginBtn--google:before {
  border-right: #BB3F30 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png') 6px 6px no-repeat;
}

.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #E74B37;
}

.header_logo {
  width: 155px;
}

.img_banner_home {
  height: 403px;
  width: 100%;
  margin-top: 14px;

}
.img_banner_home_3{
  height: 406px;
  width: 100%;
  margin-top: 0px;
}
.image_banner_home_4{
  height: 406px !important;
  /* height: 600px; */
  width: 100%;
  margin-top: 0px;
}
.image_banner_home_4::before{
  content: '';
  /* background-color: rgba(0, 0, 0, 0.7); */
  background-color: #000;
  opacity: 0.2;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.shared-offileheader .carousel-container_t4 {
  margin: 10px 0px 0px;
  /* height: 418px; */
}

.img_banner_home img {
  /* height: 100%;
  width: 100%; */
  object-fit: cover;
}
.img_banner_home img {
  height: 403px !important;
  position: static !important;
}
.Captcha_Component-div {
  margin-top: 20px;
}

.Captcha_Component-div span {
  margin: 5px 0 0;
  font-size: 12px;
  letter-spacing: .3px;
  text-align: left;
  /* color: var(--primary_color); */
  color: red;
}


.change_password .profile_model_body_1_1 {
  padding: 30px 106px;
}

.all_section {
  padding: 35px 10px 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px 10px;
}

.section_div {
  width: 31%;
  padding: 10px;
  /* text-align: center; */
  border-radius: 10px;
  border: solid 1px #30c8ca;
  background-color: #fff;
}

.section_div >div >label {
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: normal;
  text-align: center;
  color: var(--font_color);
  margin-top: 15px;
  margin-bottom: 5px;
  display: block;
}

.section_div >div >img {
  height: 30px;
  width: 30px;
  object-fit: scale-down;
  margin: auto;
}

.navbartab button {
  background: var(--primary_color);
  color: #fff;
  padding: 0px 10px 6px;
  border-radius: 4px;
  margin-right: 10px;
}

.new-header {
  width: 100%;
  background: #fff;
  height: 90px;
  position: fixed;
  z-index: 99;
  box-shadow: 0px 4px 5px 0 rgb(0 0 0 / 10%);
}

.new-homepage .container {
  width: 1270px !important;
  margin: 0 auto;
  max-width: 1260px;
}

.logindrop {
  position: absolute;
  width: 250px;
  object-fit: contain;
  box-shadow: 0 0 5px 2px rgb(0 0 0 / 15%);
  background-color: #ffffff;
  right: 1px;
  top: 42px;
  padding: 10px;
  user-select: none;
  text-align: left;
  z-index: 99;
}
.logindrp{
  border-radius: 5px;
}
.drpHelp:before{
  content: "";
  display: block;
  position: absolute;
  top: -7px;
  left: 121px;
  border-color: #e0e0e0;
  border-left: 8px solid #fff;
  border-bottom: 8px solid #fff;
  border-right: 8px solid transparent;
  border-top: 8px solid transparent;
  transform: translateX(-50%) rotate(135deg);
  box-shadow: -2px 2px 3px rgba(57,73,76,.2);
}
.drpLogin::before{
  content: "";
  display: block;
  position: absolute;
  top: -7px;
  right: 2px;
  border-color: #e0e0e0;
  border-left: 8px solid #fff;
  border-bottom: 8px solid #fff;
  border-right: 8px solid transparent;
  border-top: 8px solid transparent;
  transform: translateX(-50%) rotate(135deg);
  box-shadow: -2px 2px 3px rgba(57,73,76,.2);
}

.logindrop .ax-head-menu {
  padding: 10px;
  cursor: pointer;
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
  margin: 10px 0 !important;
}

.logindrop .ax-head-menu img {
  display: inline;
  margin-right: 10px;
  vertical-align: middle;
  width: 20px;
}

.supportdrop {
  position: absolute;
  width: 285px;
  height: 114px;
  object-fit: contain;
  box-shadow: 0 0 5px 2px rgb(0 0 0 / 15%);
  background-color: #ffffff;
  right: 250px;
  top: 0px;
  padding: 10px;
  user-select: none;
  text-align: right;
}

.new-header a .theme1_header_logo__LnkRr {
  height: 60px;
  width: auto;
  cursor: pointer;
}

.footer_phone span {
  font-family: var(--font_family);
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  text-align: left;
  color: #fff;
}

.footer_main {
  display: none;
}


/* about-us */

.container {
  width: 1200px;
  margin: 0 auto;
}

.et-office-section {
  width: 100%;
  display: flex;
  gap: 20px;
  margin-top: 20px;
  /* justify-content: space-between; */
}

.et-office-section-1 {
  width: 100%;
  background-color: #eff0f0;
  padding: 30px;
}

.et-ofc {
  width: 50%;
  height: 150px;
}

.elevate-office-location {
  width: 100%;
  /* background-color: #a0a0a0; */
  height: 185px;
  border-radius: 10px;
  position: relative;
}

.about-us-content {
  padding-bottom: 40px;
}

.about-us-content p {
  margin-bottom: 20px;
}

.et-offce-loc p.et-location {
  font-family: var(--font_family);
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
  margin-bottom: 5px;
  margin-top: 0;
}

.et-offce-loc p.sub-location {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
  line-height: 1.36;
  margin-top: 0;
}

.about-content h3 {
  font-family: var(--font_family);
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
 color: var(--primary_color);
  cursor: pointer;
}

.contactus-txt {
  width: 60%;
  font-family: var(--font_family);
  font-size: 35px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
}

.contactus-contact-support.active p {
  font-family: var(--font_family);
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
  /* padding-left: 20px; */
  margin-top: 0;
  margin-bottom: 0;
}

.about-us {
  margin-top: 0px;
  margin-bottom: 40px;
  padding-top: 90px;
}

/* contact */

.contact_Us {
  margin-top: 20px;
  margin-bottom: 40px;
}

.about_head a {
  font-family: var(--font_family);
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
 color: var(--primary_color);
  cursor: pointer;
}

.landing_tabcontainer {
  /* width: 94% !important; */
  width: 1200px;
  margin: 0 auto !important;
  padding: 30px 0 0 0 !important;
  /* border: 1px #f00 solid; */
  /* display: inherit!important; */
}

.main_contact_container {
  width: 100%;
  display: flex;
  margin-top: 30px;
}

.contactus_left {
  width: 75%;
  margin-bottom: 30px;
}

.contactus_right {
  width: 25%;
  margin-top: 130px;
  margin-left: 15px;
}

.contactus_left_panel {
  width: 100%;
  height: 130px;
  display: flex;
  gap: 10px;
}

.form1.contact_form {
  width: 100%;
  background-color: #ffff;
}

.contactus_txt {
  width: 60%;
  font-family: var(--font_family);
  font-size: 35px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--font_color);
}

.contactus_contact_support.active {
  background-color: #ddd;
  cursor: pointer;
}

.contactus_contact_support {
  width: 20%;
  border-radius: 5px;
  padding: 20px;
}

.etcontact_no, .etcontact_no_mob {
  display: flex;
  align-items: center;
}

.contactus_contact_support.inactive {
  background: #fff;
}
p.etemail {
  font-weight: bold;
  margin-bottom: 10px;
}
p.et_inqiey {
  font-family: var(--font_family);
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
  margin-bottom: 0;
}
.texthead {
  margin-bottom: 20px;
}
.contactus_contact_support img {
  width: 20px;
}


.contactus_contact_support.inactive p, .contactus_contact_support.active p {
  font-family: var(--font_family);
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
 color: var(--primary_color);
  /* padding-left: 20px; */
  margin-top: 10px;
  margin-bottom: 0;
}

.helpyoutxt {
  font-family: var(--font_family);
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
 color: var(--primary_color);
}

.et_customers {
  font-family: var(--font_family);
  border-collapse: collapse;
  width: 100%;
  margin-top: 25px;
  text-align: left;
  margin-bottom: 70px;
  font-size: 16px;
}

tr.dootedline {
  border-bottom: 1px solid #ddd;
  /* height: 36px; */
}

.et_customers td,
.et_customers th {
  padding: 20px;
}

.texthead {
  font-family: var(--font_family);
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
 color: var(--primary_color);
}

.form1.contact+form {
  width: 100%;
  background-color: #ffff;
}

.email_phn {
  width: 100%;
  height: inherit;
  padding: 20px;
  background-color: #ffff;
  border-radius: 12px;
  margin-bottom: 25px;
}

.contact_content {

  background-color: #eff0f0;
  /* margin-top: 60px; */
}

.et_customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.64px;
  text-align: left;
  background-color: #ddd;
  color: var(--font_color);
  width: 33.333%;
}

.form_maindiv {
  padding: 25px;
}

.faqs {
  margin-top: 40px!important;
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 40px;
}

.terms_condition h1.terms_n_condition_heading {
  font-family: var(--font_family);
  font-size: 24px;
  font-weight: bold;
  color: var(--font_color);
  text-align: center;
  margin-top: 30px;
}
.terms_condition h3.tnc_subheading {
  font-family: var(--font_family);
  font-size: 20px;
  font-weight: bold;
  color: var(--font_color);
  margin: 10px 0px;
}
.terms_condition p, .tc_list {
  font-family: var(--font_family);
  font-size: 16px;
  font-weight: normal;
  color: var(--font_color);
  padding-left: 25px;
  padding-bottom: 10px;
}

.terms_condition {
  padding-top: 90px;
  padding-bottom: 40px;
}
.et_payment_img{
  width: 130px;
}
.et_payment_img2{
  width: 60px;
}
.flex-row{
  display: flex;
}
.payment {
  width: 1200px;
  margin: 0 auto;
  padding: 50px 0px;
}
.flex-row.center {
  justify-content: center;
}
.css-1q2h7u5.Mui-selected{
  color: var(--primary_color) !important;
  border-bottom: 2px solid var(--primary_color);
}
.MuiTabs-indicator {
    background-color: transparent !important;
}

/************* Multiselect dropdown Style *************/

.multiSelectContainer .searchBox {
  /* border: 1px solid #737373 !important; */
  /* margin-top: 3px; */
  border: none !important;
  margin-top: 0 !important;
  padding: 0px 5px !important;
}
span.chip.false.false {
  background: var(--primary_color) !important;
}
.multiSelectContainer li:hover {
  background: var(--primary_color) !important;
  color: #fff;
  cursor: pointer;
}
.highlightOption {
  background: var(--primary_color) !important;
  color: #fff;
}
input.searchBox {
  background: #fff !important;
  display: block;
  width: 100%;
  border: none;
}
.multiSelectContainer input[type="checkbox"] {
  background: #fff !important;
  border: 1px solid #6a6a6a !important;
  margin-top: 3px;
}

.multiSelectContainer .searchWrapper{
  border: none !important;
  padding: 0 !important;
}
/************* Multiselect dropdown Style *************/


.btn-google-icon_th2{
  /* box-shadow: 0 0 5px 0 rgba(0,0,0,.3)!important; */
    width: 100%;
    font-family: var(--font_family);
    font-size: 15px;
    border-radius: 20px;
    font-weight: 400;
    letter-spacing: .38px;
    text-align: left;
    color: #4a4a4a;
    border-radius: 5px;
    border: solid 1px var(--primary_color);
    padding: 10px;
}
.btn-google-icon_th2 img{
  width: 25px !important;
  height: 25px !important;
}
/* .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select{
  display: flex !important;
  padding: 10px !important;
  align-items: center;
  justify-content: space-evenly;
} */
/* .react-datepicker__month-dropdown-container--select, .react-datepicker__year-dropdown-container--select{
  display: inline-block !important;
    margin: 0 2px !important;
} */

.search_container{
  max-width: 1260px !important;
  margin: auto;
}

.child_slider_bg{
  height: 500px;
  width: 100%;
  position: relative;
  margin: auto;
  max-width: 3840px;
}
.child_slider_bg img{
  height: 100%;
  width: 100%;
  aspect-ratio: 16/9;
}
.react-datepicker-popper{
  z-index: 2 !important;
  padding: 0 !important;
}

.react-datepicker-popper {
  width: 100%;
  min-width: 300px;
}
 .theme4_calanderrelative__APRQr .react-datepicker__input-container{
  
  border-radius: 5px !important;
  border: solid 1px #bebebe;
  padding: 10px;
  font-family: var(--font_family);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.33px;
  text-align: left;
  color: var(--font_color);
  outline: none;
  width: 100%;
  margin-top: 7px;
  height: 50px;
 }
 .theme4_calanderrelative__APRQr .react-datepicker{
  width: 275px;
 }
 .theme4_calanderrelative__APRQr .react-datepicker__month-container {
  float: left;
  width: 273px;
}
.theme4_visa_row_2_column_div__ykm4b.theme4_calanderrelative__APRQr select
{
  width: 90px;
}

@media only screen and (height: 768px) and (width: 1024px){
  body{
    zoom: 70%;
  }
}


/* @media only screen and (min-width: 1400px) and (max-width: 2139px) {
  body{
    zoom: 80%;
  }
}  */


@media only screen and (min-width: 1025px) and (max-width: 1250px) {
  body{
    zoom: 85%;
  }
} 

@media only screen and (min-width: 2140px) and (max-width: 3850px) {
  body{
    zoom: 180%;
  }
} 


/* @media (width: 1920px) and (height: 1080px) and (-webkit-zoom: 0.50) {
  body{
    zoom: 50%;
  }
}  */

/* .special_offer_container{
  z-index: -1 !important;
}

.special_offer_wrap:hover .special_offer_container{
  z-index: 1 !important;
} */



.ActiveKeyPress {
  margin: 10px 15px;
  cursor: pointer;
  border-radius: 50px;
  color: white;
  background-color: var(--primary_color);
}

  .ActiveKeyPress div, .ActiveKeyPress div span{
    color:#fff !important;
  }


  .ActiveKeyPress div > svg path{
    fill: #fff !important;
  }

.ActiveKeyPress div:nth-child(2) {
  width: 100% !important;
}
.carousel-container4{
  position: relative;
}
.header-bannertxt{
  color: white;
  padding: 20px;
  text-align: center;
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  top: 100px;
  width: 100%;
}
.header-bannertxt h2{
  font-size: 20px;
  font-weight: bold;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.header-bannertxt p{
  font-size: 18px;
  font-weight: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.all_sectionWdgt {
  padding: 30px 20px;
  padding-top: 25px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 94%;
  background: #fff;
  border-radius: 15px;
  margin: 0 auto;
  margin-top: -70px;
  position: relative;
}
.img_banner_homeMobile4 {
  /* height: 403px; */
  height: 240px;
  width: 100%;
  margin-top: 14px;
}
.img_banner_homeMobile4::before{
  content: "";
    background-color: #000;
    opacity: .2;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
}
.section_divService4 {
  width: 29%;
  padding: 10px;
  padding-top: 15px;
  text-align: center;
  border-radius: 15px;
  border: solid 1px var(--secondary_color);
  background-color: var(--secondary_color);
}
.section_divService4 >div >img {
  height: 30px;
  width: 45px;
  object-fit: scale-down;
  margin: auto;
}
.section_divService4 >div >label {
  font-family: var(--font_family);
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.33px;
  text-align: center;
  color: #fff;
  margin-top: 5px;
  margin-bottom: 5px;
  display: block;
}

.react-datepicker-wrapper{
  width: 100%;
}
.container-1242{
  width: 1242px;
  max-width: 1440px;
  margin: auto;
}
.container-1191{
  width: 1191px;
  max-width: 1191px;
  margin: 0 auto;
}
/* Theme4 report date design */

.Theme4_myreport_input___Uhfc .Theme4_date_wrapper__M53kx .react-datepicker__input-container{
  border: 0;
  background: transparent;
}


/* ------- THEME4 BANNER ADJUST ------- */

@media only screen and (height: 768px) and (width: 1024px){
  .image_banner_home_4{
    /* height: 406px; */
    height: 400px;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1250px) {
  .image_banner_home_4{
    /* height: 406px; */
    height: 80vh;
    width: 100%;
  }
  
.shared-offileheader .carousel-container_t4 {
  /* margin: 10px 0px 0px; */
  /* height: 400px; */
}
} 

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .image_banner_home_4{
    height: 90vh !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1250px) {
  body{
    zoom: 80%;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1920px) {
  .image_banner_home_4{
    /* height: 406px; */
    height: 70vh;
    width: 100%;
  }
  
.shared-offileheader .carousel-container_t4 {
  margin: 10px 0px 0px;
  /* height: 600px; */
}
} 


@media only screen and (min-width: 1921px) and (max-width: 2140px) {
  .image_banner_home_4{
    /* height: 406px; */
    height: 60vh;
    width: 100%;
  }
}

@media only screen and (min-width: 2140px) and (max-width: 3850px) {
  .image_banner_home_4{
    /* height: 406px; */
    height: 40vh;
    width: 100%;
  }
  
.shared-offileheader .carousel-container_t4 {
  margin: 10px 0px 0px;
  /* height: 600px; */
}

} 

.theme5_prof_dateComp__zKkbx .react-datepicker__input-container{
  border: none!important;
}


.react-datepicker__input-container{
  border-radius: 5px;
  border: solid 1px #d6d5d5;
  height: 40px;
  background: white;
  /* padding-top: 5px; */
}
.react-datepicker__input-container{
  height: 43px;
}
.react-datepicker__input-container input{
  border: none;
  height: 45px !important;
  border-radius: 5px;
}

@media only screen and (max-width: 400px){
.react-datepicker__input-container{
  padding-top: 0px;
}
}

.main_404_container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  margin-top: 0;
  z-index: 999;
}





@media only screen and (max-width: 767px) and (min-width: 320px) {
  input {
    text-overflow: ellipsis;
  }




  .theme5_my_profile_wrapper__8XxGa .react-datepicker__input-container {
    border: none;
  }
  .theme5_my_profile_wrapper__8XxGa .theme5_vt_country_select_container__nb0uk{
    border: none;
  }


  header.mobile-header.theme5 {
    position: sticky;
    top: -2px;
    z-index: 9;
  }
  .flight_search_wid_main .theme5_modal_container__5sk76 {
    z-index: 999;
}
.addguestbtn-width{
  width: 100%;
}
  

  .all_sectionWdgt{
    padding: 21px 0 0 !important;
        margin-top: 10px !important;
  }
  div.container{
      width: 100%!important;
  }
  .container{
    width: 100%!important;
}
  .react-datepicker__input-container input{
    height: 41px !important;
  }
}

input[type=checkbox]:focus {
  box-shadow: none !important;
}
.loading {
  /* background-color: #2ecc71;
  color: transparent;
  text-indent: 9999; */
  cursor: unset !important;
  pointer-events: none !important;
  overflow: hidden;
  position: relative;
}

.loading:before {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: rgb(186 214 255 / 40%);;
     animation: loading 3s linear infinite;
   transition: width 3s linear;
   width: 100%;
    right: 0%;
  transform: translateX(-100%);
}

@keyframes loading {
  100% {
    transform: rotate(360deg);
 }
}
@keyframes scale {
  0% {
    transform: scale(10);
 }
  50% {
    transform: scale(0.2);
 }
  70% {
    transform: scale(1.2);
 }
  90% {
    transform: scale(0.7);
 }
  100% {
    transform: scale(1);
 }
}

.theme5_my_profile_section__zVp8K .react-datepicker__input-container {
  border: none;
}
.theme5_my_profile_section__zVp8K .theme5_vt_country_select_container__nb0uk{
  border: none;
}
.theme5_dropdown_profile__ly9qB .theme5_vt_country_select_main_container__cMhQs {
  margin-top: 8px;
  display: block;
}

/* @media screen and (max-width: 767px) and (min-width: 320px) {
 .theme5_translate_modal__Km5RS :global .modal-content{
    background-color: none!important;
  }

} */

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf{
  display: none;
}
