.modal_container {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9999;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal_background {
  position: fixed;
  background: rgb(0, 0, 0, 0.8);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.modal_overflow {
  overflow: hidden;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 100%;
  padding: 40px 0;
}

.modal_content {
  position: relative;
  width: 95%;
  box-sizing: border-box;
  background: #fff;
  margin: auto;
  z-index: 2;
  max-width: fit-content;
  color: var(--primary_color);
  font-family: var(--font_family);
  font-size: 12px;
  padding: 0;
  height: max-content;
  border-radius: 5px;
  /* padding: 3%; */
}
.modal_content.mobile_modal {
  padding: 20px 15px;
  border-radius: 10px;
}
.modal_close svg {
  height: 25px;
  color: #919191;
  border-radius: 50px;
  position: absolute;
  z-index: 3;
  top: 8px !important;
  right: 12px;
  width: 25px;
  border: 1px solid #919191;
  padding: 3px;
}

.modal_close {
  float: right;
  cursor: pointer;
  /* position: relative;
    top: -50px;
    right: -15px; */
}

.green_popup .modal_content.mobile_modal {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.modal_container .modal_content {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}
.green_popup .modal_content.mobile_modal {
  background-color: #1a873c;
}

.green_popup .modal_content {
  background-color: #fff;
  position: relative;
  width: 84%;
  box-sizing: border-box;
  margin: auto;
  z-index: 2;
  max-width: 515px;
  color: var(--primary_color);
  font-family: var(--font_family);
  font-size: 12px;
  padding: 60px 70px;
  height: -moz-max-content;
  height: max-content;
  border-radius: 30px;
  display: block;
  min-height: auto;
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .modal_content {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 320px) {
  .green_popup .modal_content {
    background-color: green;
    position: relative;
    width: 91%;
    box-sizing: border-box;
    margin: auto;
    z-index: 2;
    max-width: 515px;
    color: var(--primary_color);
    font-family: var(--font_family);
    font-size: 12px;
    padding: 20px 15px;
    height: max-content;
    border-radius: 10px;
    display: block;
  }
  .success_email {
    padding: 20px 15px !important;
    border-radius: 10px;
  }
  .success_email .modal_close svg {
    top: 16px !important;
  }
  .modal_content {
    padding: 20px;
    /* padding-top: 45px; */
  }
  .modal_close svg {
    top: 20px;
    color: #fff;
    border: 1px #fff solid;
  }
  .success_email > div {
    text-align: center;
    margin-right: 20px;
  }
 

}

.success_email {
  position: relative;
  width: 95%;
  box-sizing: border-box;
  background: #fff;
  margin: auto;
  z-index: 2;
  max-width: 400px;
  color: var(--primary_color);
  font-family: var(--font_family);
  font-size: 15px;
  padding: 40px 100px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  border-radius: 10px;
}
@media screen and (max-width: 767px) and (min-width: 320px) {
  .modal_content {
    position: relative;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    background: var(--primary_color) !important;
    margin: auto;
    z-index: 2;
    max-width: 550px;
    color: var(--primary_color);
    font-family: var(--font_family);
    font-size: 12px;
    padding: 20px 70px;
    padding: 0px !important;
    border-radius: 0px;
    justify-content: unset !important;
  }
  .modal_content.mobile_modal {
    padding: 0px 0px;
    border-radius: 0px;
    display: block;
  }
  .modal_overflow {
    padding: 0px 0;
    position: absolute;
    top: 0;
    bottom: 0;
    height: auto;
  }
  .priceChangeAlertrow {
  }
  .priceChangeAlertrow .modal_overflow .modal_content {
    height: auto;
    background-color: #fff !important;
    margin: 0px 10px;
  }
  .priceChangeAlertrow .modal_overflow .modal_content .modal_close svg {
    top: 20px;
    color: #919191;
    border: 1px #919191 solid;
  }
  .priceChangeAlertrow
    .modal_overflow
    .modal_container
    .priceChangeAlert_popup
    .alertimg
    img {
    display: block;
    filter: none;
  }
  .priceChangeAlertrow
    .modal_overflow
    .modal_container
    .priceChangeAlert_popup
    .alertpricetitle {
    color: #266acc;
  }
  .priceChangeAlertrow
    .modal_overflow
    .modal_container
    .priceChangeAlert_popup
    .alertchange {
    color: #4d4d4d;
  }
  .priceChangeAlertrow
    .modal_overflow
    .modal_container
    .priceChangeAlert_popup
    .alertchange {
    color: #4d4d4d;
  }

  .pop_title {
    font-size: 18px;
    color: var(--primary_color);
  }
}
@media screen and (max-width: 1600px) and (min-width: 1279px) {
  .modal_overflow {
    overflow: hidden;
    height: 111vh;
  }
  .modal_content.mobile_modal {
    padding: 0px 0px;
    border-radius: 0px;
    display: block;
  }
  .modal_overflow {
    padding: 0px 0;
    position: absolute;
    top: 0;
    bottom: 0;
    height: auto;
  }
  .priceChangeAlertrow {
  }
  .priceChangeAlertrow .modal_overflow .modal_content {
    height: auto;
    background-color: #fff !important;
    margin: 0px 10px;
  }
  .priceChangeAlertrow .modal_overflow .modal_content .modal_close svg {
    top: 20px;
    color: #919191;
    border: 1px #919191 solid;
  }
  .priceChangeAlertrow
    .modal_overflow
    .modal_container
    .priceChangeAlert_popup
    .alertimg
    img {
    display: block;
    filter: none;
  }
  .priceChangeAlertrow
    .modal_overflow
    .modal_container
    .priceChangeAlert_popup
    .alertpricetitle {
    color: #266acc;
  }
  .priceChangeAlertrow
    .modal_overflow
    .modal_container
    .priceChangeAlert_popup
    .alertchange {
    color: #4d4d4d;
  }
  .priceChangeAlertrow
    .modal_overflow
    .modal_container
    .priceChangeAlert_popup
    .alertchange {
    color: #4d4d4d;
  }

  .pop_title {
    font-size: 18px;
    color: var(--primary_color);
  }
}
@media screen and (max-width: 1600px) and (min-width: 1279px) {
  .modal_overflow {
    overflow: hidden;
    /* height: 111vh; */
    height: 100vh;
  }
  .modal_content {
    max-height: 600px;
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1250px) {
}

