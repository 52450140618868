.footer_main {
    background: var(--primary_color);
    color: #fff;
    font-family: var(--font_family);
    font-size: 14px;
    padding: 40px 0;
}

.footer_logo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    font-family: var(--font_family);
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    width: 20%;
    text-align: center;
}

.footer_logo input, .footer_logo img {
    height: 100px;
    width: 100%;
    object-fit: scale-down;
    margin-bottom: 20px;
}

.footer_links a {
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.22;
    letter-spacing: -0.45px;
    text-align: left;
    color: #ffff;
    width: 33%;
    padding: 0 10px;
}

.footer_links {}

.footer_links {
    display: flex;
    flex-wrap: wrap;
    width: 45%;
}

.footer_send_email {
    width: 23%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.social_media input, .social_media img {
    height: 35px;
    width: 35px;
    object-fit: contain;
    margin: 0 8px;
}

.social_media {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 10px 0;
    justify-content: center;
}

.payment_card {
    display: flex;
    align-items: center;
    width: 100%;
    opacity: .7;
    justify-content: center;
}

.payment_card input, .payment_card img {
    height: 35px;
    width: 35px;
    object-fit: contain;
    margin: 0 8px;
}

.email_block label{
    font-family: var(--font_family);
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    margin-bottom: 5px;
    display: block;
}

.email_block input{
    padding: 6px 15px;
    margin-top: 8px;
    font-size: 14px;
    border: none;
    border-radius: 12px 0 0 12px;
    width: 65%;
    display: inline-block;
    float: left;
    color: var(--font_color);
}

.email_block button{
    padding: 8px 5px;
    margin-top: 8px;
    margin-right: 0px;
    background: var(--secondary_color);
    color: #ffff;
    font-size: 14px;
    border: none;
    cursor: pointer;
    border-radius: 0 12px 12px 0px;
    display: inline-block;
    float: left;
    width: 30%;
}

.email_block{
    margin: 0 10px;
    width: 100%;
    margin-bottom: 20px;
}

.footer_theme1_new{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: auto;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .footer_theme1_new{
        flex-direction: column;
    }
    .footer_logo{
        width: 100%;
        align-items: center;
        padding-bottom: 20px;
    }
    .footer_send_email{
        width: 90%;
        margin: auto;
    }
    .footer_links a {
        width: 100%;
    }
    .footer_links {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-left: 10px;
        margin-bottom: 15px;
    }
    .footer_logo input, .footer_logo img{
        height: inherit;
    }
}