.footer {
    justify-content: center;
    align-self: stretch;
    background: var(--boarder, #e5e7eb);
    display: flex;
    flex-direction: column;
    padding: 23px 0px;
}
.footerMain_container{
    width: 1191px;
    max-width: 1191px;
    margin: 0 auto;
}

.footerContainer {
    display: flex;
    width: 100%;
    gap: 20px;
    color: var(--secondary_color);
    flex-wrap: wrap;
    justify-content: space-between;
    font: 400 15px/1.1 var(--font_family);
}

.footerNav {
    display: flex;
    gap: 40px 51px;
    margin: auto 0;
}

.footerNavLink {
  
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}



.footerBrand {
    display: flex;
    gap: 3px;
    align-items: center;
}

.footerBrandText {
    margin: auto 0;
}

.footerBrandLogo {
    aspect-ratio: 4.31;
    object-fit: contain;
    width: 125px;
}

.footerBottom {
    display: flex;
    margin-top: 27px;
    width: 100%;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.footerCopyright {
    color: var(--secondary_color);
    font: 400 12px/1.4 var(--font_family);
}

.footerSocial {
    display: flex;
    gap: 16px 24px;
}

.footerSocialIcon {
    aspect-ratio: 1;
    object-fit: contain;
    width: 24px;
}

@media (max-width: 991px) {
    .footerMain_container {
        width: auto;
        max-width: 100%;
        margin: 0;
    }
    
    .footerContainer {
        flex-direction: column-reverse;
        align-items: center;
        gap: 32px;
    }
    
    .footer {
        display: flex;
        flex-direction: column;
        padding: 0px 15px;
        padding-top: 35px;
        padding-bottom: 25px;
    }
    
    .footerNav {
        gap: 10px 26px;
        flex-wrap: wrap;
    }
    
    
    .footerBottom {
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 31px;
        gap: 24px;
    }
    
    .footerBrand {
        gap: 15px;
    }
    
    .footerBrandText {
        color: var(--secondary_color);
        font-family: var(--font_family);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; /* 13.2px */
    }
    
    .footerBrandLogo {
        height: 24px;
        width: auto;
    }
    
    .footerNavLink {
        color: var(--secondary_color);
        font-family: var(--font_family);
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; /* 14.3px */
    }
    
    .footerSocial a img {
        width: 24px;
        height: 24px;
    }
    
    .footerCopyright {
        color: var(--secondary_color);
        text-align: center;
        font-family: var(--font_family);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; /* 16.8px */
    }
    .footerHome{
        margin-bottom: 72px;
    }
}

  
  
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
    .footerMain_container{
      width: 100%;
      padding: 0 20px;
    }
  }