.modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  .modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    z-index:999;
    width: 400px;
  }
  .assist_book_cont{
    padding: 0 15px;
  }

  .agnc_head{
    font-size: 16px;
    font-family: var(--font_family);
    font-weight: 600;
    color: var(--font_color);
    margin-bottom: 5px;
  }
  
  .select_bx{
    height: 40px;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 10px;
  }
  .subagncy_label{
    font-size: 14px;
    color: var(--font_color);
    font-family: var(--font_family);
    margin-bottom: 5px;
  }
  .ok_btn{
    border: 1px solid var(--buttons_color);
    display: block;
    margin: auto;
    background: var(--buttons_color);
    color: #fff;
    padding: 6px 15px;
    border-radius: 10px;
  }

  .dropdown {
    /* border: 0.5px solid grey; */
    /* padding-top: 10px; */
    /* margin-top: -12px; */
    box-shadow: 0px 0px 2px 0px black;
    /* padding-left: 20px; */
    border-radius: 10px;
    max-height: 120px;
    width: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .dropdown::-webkit-scrollbar {
    display: none; /*for chrome, safari, opera */
  }

  .dropdown .users {
    padding-left: 20px;
    margin-bottom: 5px;
    margin-top: 5px;
    cursor: pointer;
    font-family: var(--font_family);
    font-size: 16px;
  }

  .dropdown .users:hover {
    background-color: grey;
    color: white;
  }

  .crossbutton {
    width: 20px;
    height: 20px;
    float: right;
    cursor: pointer;
    border: 1px solid var(--font_color);
    border-radius: 50%;
    background-color: var(--font_color);
    color: white;
  }

  .login_loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid var(--font_color);
    width: 38px;
    height: 38px;
    /* position: relative; */
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin: auto;
    /* top: -76px;
    left: 45%;
    right: 50%; */
  }