.navigation{
    width: 100%;
    height: 50px;
}


.navigation ul{
    display:flex;
    flex-wrap: wrap;
    float: right;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
}
.navigation ul li{
    list-style-type: none;
    padding-right: 10px;

}



.hamburger{
    display: none;
    z-index: 6;
} 

.mobile_sidenav{
    border-style: none;
    display: flex;
    color: var(--font_color);
    font-size: 15px;
    letter-spacing: 0.38px;
    overflow: hidden;
    padding: 16px;
    text-decoration: none;
    height: 100%;
    box-sizing: border-box;
    cursor: pointer;
    gap: 10px;
}
 .mobile_sidenav:hover {
   color: var(--primary_color);
    font-weight: bold;
  }
  .mobile_sidenav:hover .bui_u_margin8 {
   color: var(--primary_color);
    background-color: #256380;
  }
   .mobile_sidenav:hover img {
    color: #fff;
    filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(138deg) brightness(103%) contrast(220%);
  }
.my_settings_section_card_icon{
    background-color: #e7e7e7;
      box-sizing: border-box;
      border-radius: 50%;
      height: 36px;
      width: 36px;
      line-height: 18px;
      padding: 7px 8px;
      /* padding-left: 12px; */
  }
  .mob_brd:after {
    content: " ";
    height: 0;
    border-radius: 2px;
    display: block;
    border: 1px #cdcdcd;
    border-style: solid solid none;
  }
  .bui_u_margin8{
    margin-right: 8px;
  }
  .menu_item{
    margin: auto 0;
  }

  .desktop_link_active{
    color: var(--primary_color);
    opacity: 1;
}

.desktop_link_inactive{
    border-bottom: 1px solid rgb(221, 221, 221, 0.54);
    pointer-events: none;
    position: relative;
    top: -1px;
    margin-bottom: 20px;
}
@media (max-width: 767px){
  
    .hamburger{
        display:fixed;
        padding-top: 10px;
        margin-left: 10px;
        z-index: 6;
    }


   
    .navigation {
        display:  none;
        background-color:white ;
        height: 100vh;
        width: 50vw;
        margin-top: 50px;
        position: fixed;
        

    }
    .navigation_ineline {
      display: flex !important;
    background-color: white;
    /* height: 100vh; */
    width: 90vw;
    /* margin-top: 38px; */
    position: absolute;
    /* margin-left: -350px; */
    /* position: fixed; */
    top: 0;
    left: 0;
    /* bottom: 0; */
    flex-direction: column;
    border-radius: 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* padding: 0 10px; */
    height: 100vh;
    overflow: scroll;
    }

    .mobile-layoutwrapper .navigation_ineline{
      min-height: 400px;
      overflow-y: scroll;
    }

    /* .navigation_ineline:before{
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      margin-left: 0;
      bottom: 0;
      top: 0;
      right: -7px;
      box-sizing: border-box;
      border-color: transparent transparent #fff #fff;
      border-style: solid;
      border-width: 7px;
      transform-origin: 0 0;
      transform: rotate(130deg);
      box-shadow: -3px 3px 2px 0px rgb(0 0 0 / 15%);
    } */


    .navigation_ineline .profile_frst span{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 15px;
      font-weight: 400;
      margin: 3px 0;
      cursor: pointer;
      color: var(--primary_color);
      border-bottom: 1px solid #dcdcdc;
      padding: 10px 0;
    }

    .navigation_ineline > div > div > span > div{
      padding: 5px;
      border-radius: 50px;
      margin-right: 2px;
    }
    .navigation_ineline > div > div > span > div img{
      padding: 2px;
      filter: brightness(0) saturate(100%) invert(18%) sepia(69%) saturate(1225%) hue-rotate(166deg) brightness(94%) contrast(102%);
      /* filter: brightness(4.5); */
    }

    .navigation_ineline .profile_frst{
      padding: 0 10px;
      padding-bottom: 100px;
    }
    .profile_User{
      padding: 0;
    }
    
    .navigation_ineline > div > div:last-child > div{
      font-size: 18px;
      font-weight: 600;
      padding-top: 20px;
      padding-bottom: 10px;
    }

    .navigation_ineline > div > div:last-child > a span{
      cursor: pointer;
      color: var(--font_color);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 12px 0;
    }

    .navigation_ineline > div > div:last-child > a span div{
      padding: 5px;
      background: var(--secondary_color);
      border-radius: 50px;
      margin-right: 12px;
    }
    
    .navigation_ineline > div > div:last-child > a span div img{
      filter: brightness(10);
      padding: 2px;
    }

    .navigation_ineline > div > div > span:hover{
      font-weight: 700;
      color: var(--primary_color);
    }

.User_container {
  display: flex;
  align-items: center;
  background-color: #548dff; 
  color: white;
  font-size: 24px;
  font-family: var(--font_family);
  padding: 15px;
  height: 87px;
}
.flex_rowalign{
  display: flex;
  align-items: center;
}

.user_icon {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.user_icon img {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.welcome_email{
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.38px;
  text-align: left;
  color: #fff;
  text-transform: capitalize;
}
.welcome_currency{
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.38px;
  text-align: left;
  color: #fff;
  text-transform: capitalize;
}

.welcome_message {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.38px;
  text-align: left;
  color: #fff;
  text-transform: capitalize;
  white-space: nowrap;
        width: 240px!important;
        text-overflow: ellipsis;
        overflow: hidden;
}
.welcome_message_right{
  width: 247px;
}
.welcome_email{
  white-space: nowrap;
  width: 240px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.branch_container {
  display: flex;
  justify-content: space-between;
  /* align-items: flex-end; */
  flex-direction: column;
  background-color: #fff;
  border-bottom: 1px solid 
  #dcdcdc; 
  margin: 0 10px;
  padding: 6px 0;
}
.nav_menu_backdrop{
  background: #00000066;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 0;
}
.assisted_booking{
  /* text-align: end; */
}
.branch_name, .assisted_booking {
  margin: 2px 0;
}

.branch_name {
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  color: var(--primary_color);
}
.branch_flx{
  display: flex;
  align-items: baseline;
  gap: 2px;
  text-transform: capitalize;
}
.banch_wd{
  width: 60%;
  font-weight: 600;
  font-size: 13px;
  line-height: 1.5;
}

.assisted_booking button {
  padding: 5px 15px;
  background-color: var(--buttons_color); 
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 11px;
  font-weight: bold;
  line-height: 1.68;
  letter-spacing: 0.28px;
  text-align: center;
  color: #fff;
}


}