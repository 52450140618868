.manageBooking_modal_container{

}
.manage_booking_container{
  max-width: 391px;
  margin: 0 auto;
}
.Filt_inp_bx input::placeholder, .Filt_inp_bx .select_title{
  color: #7f747c;
}
.manage_booking_heading .manage_book_heading{
  font-family: var(--font_family);
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px; 
  letter-spacing: normal;
  text-align: left;
  color: var(--secondary_color);
}
.manage_booking_heading .manage_book_label{
font-family: var(--font_family);
font-size: 16px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 28px; 
letter-spacing: normal;
text-align: left;
color: var(--secondary_color);
}
.form_flex {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  position: relative;
}
.label_input {
  display: flex;
      flex-direction: column;
}
.label_input label{
  color: var(--secondary_color);
  font-family: var(--font_family);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal; /* 215.385% */
  margin-bottom: 10px;
}
.form_flex input{
  border-radius: 5px 0px 5px 5px;
  border: 1px solid #C5C5C5;
  background: #FFF;
  height: 54px;
  padding: 22px 10px;
  color: var(--font_color);
  font-family: var(--font_family);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.form_flex input:focus{
  border: 1px solid #C5C5C5;
  box-shadow: none;
}
.form_flex input::placeholder{
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.Filt_inp_Wrap{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.filt_btn_bx {
  margin-top: 30px;
}
.Filt_apply{
  height: 44px;
  border-radius: 5px 0px 5px 5px;
  background: var(--buttons_color);
  color: #FFF;
  font-family: var(--font_family);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 15px;
}
.space_remove{
  margin-top: 0;
}
.error_message {
  padding-top: 0px;
  padding-left: 0px;
}
.error_message  span{
font-family: var(--font_family);
font-size: 11px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 28px;
letter-spacing: normal;
text-align: left;
color: #FF0101;
}
.question_suggest {
  padding-top: 8px;
  padding-bottom: 16px;
}
.question_suggest .question_suggest_details{
  color: var(--secondary_color);
  font-family: var(--font_family);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
}
.question_suggest .question_suggest_details span{
  color: var(--primary_color);
  padding-right: 2px;
}
.question_suggest .question_suggest_details a{
  color: var(--primary_color);
  padding-right: 2px;
}

.manageBooking_modal_container :global .modal-content {
     max-width: 685px;
    height: auto;
    flex-shrink: 0;
    border-radius: 15px;
    background: #FFF;
    display: block !important;
    padding-top: 56px;
    padding-bottom: 44px;
}
.manageBooking_modal_container :global .modal-close svg {
  padding: 0;
  border: 0;
  color: #000;
}


@media only screen and (max-width: 767px) and (min-width: 320px){
  .form_flex {
    display: grid;
  }
  .form_flex input{
    width: 100%;
  }
  .label_input {
   padding-bottom: 0px;
  }
  .error_message {
    position: unset;
    top: 0px;
  }
  .filt_btn_bx {
    margin-top: 30px;
  }
  .space_remove{
    margin-top: 0;
  }
  .manageBooking_modal_container :global .modal-content { 
    height: 100vh;
    border-radius: 0;
    background-color: #fff !important;
}
.header_Container{
  
}
.header_wrapper{
  padding: 15px 15px;
  background: #FFF;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  gap: 9px;
}
.header_title{
  color: var(--secondary_color);
font-family: var(--font_family);
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 155.556% */
}
.manage_booking_container {
  max-width: 100%;
  margin-top: 93px !important;
  margin: 0 14px;
  border-radius: 5px 0px 5px 5px;
  border: 1px solid #E5E7EB;
  padding: 35px 14px;
  background: #fff;
}
.manage_booking_heading {

}
.header_back_arrow{}
.manageBooking_modal_container :global .modal-close svg {
  top: 16px !important;
}
.manage_booking_heading .manage_book_heading {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.manage_booking_heading .manage_book_label {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.question_suggest .question_suggest_details {
  font-size: 11px;
  font-weight: 500;
}

}